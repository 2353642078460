import snack from "components/wrapper/snack";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  orderStatusFailure,
  orderStatusStart,
  orderStatusSuccess
} from "redux/reducers/orderStatusUpdate";
import { 
  orderAcknowledged,
  orderCancel, 
  releaseForShipment,
  shipOrder
} from "redux/services/orderDetails";
import { StatusActions } from "components/Content/OrderDetails";

function* orderStatusUpdateSagaWatcher() {
  yield takeLatest([orderStatusStart.type], orderStatusUpdateWorker);
}

function* orderStatusUpdateWorker(action: any): any {
  try {
    switch (action.type) {
      case orderStatusStart.type:
        {
          const type = action.payload.action;
          let response: any; 
          // Acknowledge order
          if(type === StatusActions.AcknowledgedBySeller) {
            response = yield call(orderAcknowledged, action.payload);
          }
          // Release for shipment
          else if(type === StatusActions.ReleasedForShipment) {
            response = yield call(releaseForShipment, action.payload);
          }
          // Ship order
          else if(type === StatusActions.Shipped) {
            response = yield call(shipOrder, action.payload);
          }
          // Cancel order
          else if(type === StatusActions.Cancelled) {
            response = yield call(orderCancel, action.payload);
          }
          // If successful api call
          if(response) {
            yield put(orderStatusSuccess({ response }));
          }
        }
        break;
        
      default:
        break;
    }
  } catch (err: any) {
    console.error(`Error occuring while calling an action ${action.type}`, err);
    console.error(err?.response?.data?.msg);
    yield put(orderStatusFailure());
  }
}
export default orderStatusUpdateSagaWatcher;
