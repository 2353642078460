import {axiosInstanceMain} from "api/axiosController";
export const getProducts = async (data: any) => {
  let uri = `product/search?search=${data.query}&page=1`;

  try {
    const res = await axiosInstanceMain.get(uri);
    return res.data;
  } catch (e) {
    throw e;
  }
};  

export const getProductByID = async (data: any) => {
  let uri = `product/${data}`;
  try {
    const res = await axiosInstanceMain.get(uri);
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const getFilterBasedProducts = async (data: any) => {
  const { categoryId } = data;
  let uri = "";

  let filterKeys = Object.keys(data.filter);

  let filterStr = `product/${categoryId}?`;

  for (let i = 0; i < filterKeys.length; i++) {
    if (!data.filter[filterKeys[i]]) continue;
    if (i === 0) {
      filterStr = filterStr.concat(
        `${filterKeys[i]}=${data.filter[filterKeys[i]]}`
      );
    } else {
      filterStr = filterStr.concat(
        `&${filterKeys[i]}=${data.filter[filterKeys[i]]}`
      );
    }
  }
  uri = filterStr + "&priceMin=0&page=1";
  // uri = `product/${categoryId}?color=${filter.color}&brand=${filter.brand}&material=${filter.material}&priceMin=${filter.priceMin}&priceMax=${filter.priceMax}&page=${1}`;

  try {
    const res = await axiosInstanceMain.get(uri);
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const getProductImage = async (imageLinks: any) => {
  let uri = "product/get-product-images";
  try {
    const res = await axiosInstanceMain.post(uri, { imageLinks });
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const getAllProductBasedOnFilter = async (data: any) => {
  let uri = "product/allProducts";
  try {
    const res = await axiosInstanceMain.get(uri, data);
    return res.data?.ResponseBody?.products;
  } catch (e) {
    throw e;
  }
};

export const createProduct = async (data: any) => {
  let uri = `/product/createSellerProduct`;

  try {
    const res = await axiosInstanceMain.post(uri, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("seller-access-token")}`,
      },
    });
    return res.data?.ResponseBody;
  } catch (e) {
    throw e;
  }
};

export const updateProduct = async (data: any) => {
  let uri = `/product/updateProduct`;

  try {
    const res = await axiosInstanceMain.post(uri, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("seller-access-token")}`,
      },
    });
    return res.data?.ResponseBody;
  } catch (e) {
    throw e;
  }
};
export const deleteProduct = async (data: any) => {
  let uri = `/product/deleteProduct`;

  try {
    const res = await axiosInstanceMain.post(uri, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("seller-access-token")}`,
      },
    });
    return res.data?.ResponseBody;
  } catch (e) {
    throw e;
  }
};
export const getActiveSellItemProductList = async (data: any) => {
  let uri = `/product/getActiveSellItemProductList`;

  try {
    const res = await axiosInstanceMain.get(uri, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("seller-access-token")}`,
      },
    });
    return res.data?.ResponseBody;
  } catch (e) {
    throw e;
  }
};

export const getSoldItemsProductList = async (data: any) => {
  let uri = `/orders/history-for-seller`;

  try {
    const res = await axiosInstanceMain.get(uri, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("seller-access-token")}`,
      },
    });
    return res.data?.ResponseBody;
  } catch (e) {
    throw e;
  }
};

export const uploadProductImages = async (data: any) => {
  let uri = `/product/uploadSingleProductImages`;
  const formData = new FormData();
  data.forEach(({ file }: any, index: number) => {
    if (file) {
      formData.append(
        `images`,
        new Blob([file], { type: file.type }),
        file.name
      );
    }
  });
  try {
    const res = await axiosInstanceMain.post(uri, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("seller-access-token")}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return res.data?.ResponseBody;
  } catch (e) {
    throw e;
  }
};
