import axios from "api/axiosController";
import { getAccessToken } from "utils/auth";

export const getSessionsHistoryAPI = async (sellerId:string) => {
    let uri = '/bulk-upload-logs';
    try {
        const res = await axios.get(
            uri+"/"+sellerId,
            { headers: { Authorization: `Bearer ${getAccessToken()}` } }
        );

        return res.data;

    } catch (error) {
        return error;
    }
}