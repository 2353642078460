import React from "react";
import Grid from "@mui/material/Grid";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import "./styles.css";
const CategoryItem = ({
  categoryDetails,
  index,
  isFinal,
  handleDigDown = () => {},
}: any) => {
  if (!categoryDetails && categoryDetails.categoryName) {
    return null;
  }
  const { categoryName, categoryId } = categoryDetails;
  return (
    <Grid
      item
      key={categoryName + categoryId + index}
      xs={12}
      className="digDownGrid"
      onClick={() => handleDigDown({ categoryName, categoryId })}
    >
      <Grid
        container
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Grid item xs={9}>
          {categoryName}
        </Grid>
        {categoryId && categoryId !== "null" ? (
          <Grid item xs={2}>
            <Grid container direction={"row"} justifyContent={"flex-end"}>
              <Grid item>
                <KeyboardArrowRightIcon className="arrowRightIcon" />
              </Grid>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
      {!isFinal ? <hr className="hrLineForCategories" /> : null}
    </Grid>
  );
};

export default CategoryItem;
