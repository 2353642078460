import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchCategoriesSuccess,
  fetchCategoriesFailure,
  fetchCategoriesStart,
  fetchCategoriesStartForSellLikeItem,
  fetchChildCategoryStartForSellLikeItem,
  fetchCategoriesStartForSellLikeItemSuccess,
  fetchChildCategoryStartForSellLikeItemSuccess,
} from "redux/reducers/categories";
import {
  getCategoryData,
  getTopCategories,
  getChildCategories,
} from "redux/services/categories";

export const alchohal: any = { "499676": true, "3577": true };

function* categorySagaWatcher() {
  yield takeLatest(
    [
      fetchCategoriesStart.type,
      fetchCategoriesStartForSellLikeItem.type,
      fetchChildCategoryStartForSellLikeItem.type,
    ],
    categorySagaWorker
  );
}

function* categorySagaWorker(action: any): any {
  try {
    switch (action.type) {
      case fetchCategoriesStart.type:
        {
          const data = yield call(getCategoryData);
          const topCategories = yield call(getTopCategories);
          yield put(
            fetchCategoriesSuccess({
              topCategories: topCategories,
              categories: data,
            })
          );
        }
        break;
      case fetchCategoriesStartForSellLikeItem.type:
        {
          const data = yield call(getCategoryData);
          yield put(
            fetchCategoriesStartForSellLikeItemSuccess({
              categories: data.ResponseBody,
              basedOnCatergoryId: {},
              intialLoadFromUpdateProduct:
                !!action.payload?.intialLoadFromUpdateProduct,
            })
          );
        }
        break;

      case fetchChildCategoryStartForSellLikeItem.type:
        {
          const data = yield call(getChildCategories, action.payload);
          const useUps = !!alchohal[data?.ResponseBody?.parentId];
          yield put(
            fetchChildCategoryStartForSellLikeItemSuccess({
              basedOnCatergoryId: {
                [action?.payload?.categoryId || ""]:
                  ((data || {})?.ResponseBody || {})?.childData || [],
              },
              categoryBreadCrum: `${action.payload?.categoryName}#${action.payload?.categoryId}`,
              categoryId: action?.payload?.categoryId,
              categoryName: action?.payload?.categoryName,
              useUps,
              intialLoadFromUpdateProduct: false,
            })
          );
        }
        break;

      default:
        break;
    }
  } catch (err: any) {
    console.error(`Error occuring while calling an action ${action.type}`, err);

    if (action.type === fetchCategoriesStart.type) {
      yield put(fetchCategoriesFailure());
    }
  }
}

export default categorySagaWatcher;
