import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ordersStart, updateOrders } from "redux/reducers/orderDetails";
import { Alert, Pagination, Typography } from "@mui/material";
import "./style.css";
import { RootState } from "redux/store";
import OrderStatusStepper from "./OrderStepper";
import { 
  orderStatusStart, 
  resetState
} from "redux/reducers/orderStatusUpdate";
import Snackbar from "@mui/material/Snackbar";

import { 
  Table, 
  TableHead, 
  TableBody, 
  TableRow, 
  TableCell, 
  Button,
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle
} from '@mui/material';
import failedImage from '../../../assets/images/no_image.png'
import snack from "components/wrapper/snack";
import { ExcelDownloaderTemplate } from "./excelDownloaderTemplate";

type CancelOrderDialogProps = {
  open: boolean;
  handleClose: () => void;
  handleCancelOrder: () => void;
}

export const StatusActions = {
  'Pending':0,
  'AcknowledgedBySeller':1,
  'ReleasedForShipment':2,
  'Shipped':3,
  'Cancelled':4
}

const CancelOrderDialog = ({ open, handleClose, handleCancelOrder } : CancelOrderDialogProps) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Cancel Order</DialogTitle>
      <DialogContent>
        Are you sure you want to cancel this order?
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          No
        </Button>
        <Button onClick={handleCancelOrder} color="primary" autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const OrderData = ({title, value}: {title:string, value:string}) => {
  return (
    <>
      <p style={{fontSize:'12px'}}>
        <b>{title}</b>: {value}
      </p>
    </>
  );
}


const SingleOrderTemplate = ({order, orderIndex, sellerId, handleOrderStatusChange}: {
  order:any, 
  orderIndex: number,
  sellerId: number,
  handleOrderStatusChange: (index: number, order:any, action:number) => void,
}) => {
  
  const [orderStatusPosition, setOrderStatusPosition] = useState(0);
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);

  // Update order status position state variable based on order status
  useEffect(() => {
    
    if(order.OrderStatus === 'ReleasedForShipment') {
      setOrderStatusPosition(2);
    }
    else if (order.OrderStatus === 'AcknowledgedBySeller') {
      setOrderStatusPosition(1);
    }
    else if (order.OrderStatus === 'Shipped') {
      setOrderStatusPosition(3);
    }
    else {
      setOrderStatusPosition(0);
    }

  },[order?.OrderStatus]);

  // Handle Order Status Change
  const handleUpdateOrderStatus = () => {
    // Pending - Acknowledge the product
    if(orderStatusPosition === 0) {
      handleOrderStatusChange(orderIndex, order, StatusActions.AcknowledgedBySeller);
    }
    // Acknowledged - Release for shippment
    else if (orderStatusPosition === 1) {
      handleOrderStatusChange(orderIndex, order, StatusActions.ReleasedForShipment);
    }
    // ReleasedForShipment - Ship the product
    else if(orderStatusPosition === 2) {
      handleOrderStatusChange(orderIndex, order, StatusActions.Shipped);
    }
  };

  // Handle Show Cancel Dialog Pop Up
  const handleCancelClick = () => {
    setCancelDialogOpen(!cancelDialogOpen);
  }

  // Single Product Component
  return (
    <div key={orderIndex} className="box-sh">

      {/* Order Details */}
      <div className="wishlist-detail d-flex">
        <div>Order Details</div>

        <div
          className="d-flex w-100 justify-content-between"
          style={{ paddingLeft: 50 }}>

          <div className="my-order-content-wrap">

            <OrderData 
              title={"Order ID"}
              value={order.ID}
            />

            {order.OrderStatus === 'Cancelled'?
              <p style={{fontSize:'12px', color:'red'}}>
                <b>Order Status: {order.OrderStatus}</b>
              </p>
              :
              <OrderData 
                title={"Order Status"}
                value={order.OrderStatus === "ReleasedForShipment"
                        ? order.OrderStatus
                        : order.OrderStatus.toUpperCase()}
              />
            }

            <OrderData 
              title={"Payment Status"}
              value={order.OrderPaymentStatus}
            />

          </div>

          <div className="p-4">


            <OrderData 
              title={"Order Time"}
              value={order.OrderDateUtc}
            />

            <OrderData 
              title={"Order Total Price"}
              value={'$' + order.TotalPrice.toFixed(2)}
            />

            <OrderData 
              title={"Order Total Tax"}
              value={'$' + order.TotalTaxPrice.toFixed(2)}
            />

          </div>
          <div className="p-4">

            <OrderData 
              title={"Payment Type"}
              value={order.PaymentType}
            />

            <OrderData 
              title={"Payment Cut"}
              value={'$' + order.TotalPaysferAmount.toFixed(2)}
            />

            <OrderData 
              title={"Seller Amount"}
              value={'$' + order.TotalSellerAmount.toFixed(2)}
            />

          </div>
        </div>
      </div>

      {/* Customer Details */}
      {order.address && order.address.length>0 &&(
        <div className="wishlist-detail d-flex">
        <div>Shipping Address</div>
        <div
          className="d-flex w-100 justify-content-between"
          style={{ paddingLeft: 50 }}
        >
          <div className="my-order-content-wrap">

            <OrderData 
              title={"Name"}
              value={order.address[0]?.FirstName +
                    " " + order.address[0]?.LastName}
            />

            <OrderData 
              title={"AddressLine1"}
              value={order.address[0]?.AddressLine1}
            />

            {order.address[0]?.AddressLine2 && (
              <OrderData 
                title={"AddressLine2"}
                value={order.address[0]?.AddressLine2}
              />
            )}

            <OrderData 
              title={"City"}
              value={order.address[0]?.City}
            />

          </div>
          <div className="p-4">

            <OrderData 
              title={"PostalCode"}
              value={order.address[0]?.PostalCode}
            />

            <OrderData 
              title={"State/Province"}
              value={order.address[0]?.StateOrProvince}
            />

            <OrderData 
              title={"Country"}
              value={order.address[0]?.Country}
            />
        
          </div>
          <div className="p-4">
            <OrderData 
              title={"Phone Number"}
              value={order.address[0]?.DaytimePhone}
            />

            <OrderData 
              title={"EmailAddress"}
              value={order.address[0]?.EmailAddress}
            />

          </div>
        </div>
      </div>
      )}

      {/* Order Progress Stepper */}
      {order.OrderStatus !== 'Cancelled' && 
        <div className="wishlist-detail" 
          style={{
            display:"flex", 
            justifyContent:"space-evenly",
            alignItems:'center'
          }
        }>
          <div style={{flexGrow:1}}>
            <OrderStatusStepper 
              activeStatus={orderStatusPosition}
            />
          </div>
          {/* Status Change Button */}
          <div style={{ display:"flex", flexDirection:"column", marginLeft:'20px', marginRight:'10px'}}>
            
            { order.OrderStatus !== "Shipped" &&
              <Button
                variant="contained"
                color="primary"
                style={{fontSize:"10px", marginBottom:'10px'}}
                onClick={handleUpdateOrderStatus}
              >
                {
                  order.OrderStatus === "AcknowledgedBySeller"? "Release For Shippment" :
                  order.OrderStatus === "ReleasedForShipment"? 'Ship Order' :
                  "Acknowledge Order"
                }
              </Button>
            }
            
            <Button
              variant="contained"
              color="error"
              style={{fontSize:"10px"}}
              onClick={handleCancelClick}
            >
              { order.OrderStatus === "Shipped"
                ? "Cancel and Refund Order"
                : "Cancel Order"
              } 
            </Button>
          </div>
        </div>
      }
      
       
      
      {/* Order items' table */}
      <div className="wishlist-detail" style={{ width: '100%', overflowX: 'auto' }}>
        <Typography>
          Order Items
        </Typography>
        <Table style={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell style={{minWidth:'70px'}}>Sr No</TableCell>
              <TableCell style={{minWidth:'70px'}}>Image</TableCell>
              <TableCell style={{minWidth:'100px'}}>Title</TableCell>
              <TableCell style={{minWidth:'100px'}}>Item Price</TableCell>
              <TableCell style={{minWidth:'70px'}}>Quantity</TableCell>
              <TableCell style={{minWidth:'100px'}}>SKU</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {order.Items && order.Items.map((item:any, index:number) => (
              <TableRow key={index}>
                <TableCell style={{fontSize:'12px'}}>{index + 1}</TableCell>
                <TableCell>
                  <img src={item.image} alt="Product" style={{ width: '80px', height: '80px' }} 
                    onError={(e) => {
                      const target = e.target as HTMLImageElement;
                      target.src = failedImage; // Replace source image with the failed image
                    }}
                  />
                </TableCell>
                <TableCell style={{fontSize:'12px'}}>{item.title}</TableCell>
                <TableCell style={{fontSize:'12px'}}>${item.UnitPrice.toFixed(2)}</TableCell>
                <TableCell style={{fontSize:'12px'}}>{item.Quantity}</TableCell>
                <TableCell style={{fontSize:'12px'}}>{item?.SellerSKU}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      {/* Cancel Order Dialog */}
      <CancelOrderDialog
        open={cancelDialogOpen}
        handleClose={handleCancelClick}
        handleCancelOrder={() =>  {
          handleOrderStatusChange(orderIndex, order, StatusActions.Cancelled)
          handleCancelClick()
        }}
      />
      
    </div>
  );
};


const OrderDetails = () => {
  const dispatch = useDispatch();
  const orders = useSelector((state: RootState) => state.orderDetails.orders);
  const orderCount = useSelector(
    (state: RootState) => state.orderDetails.orderCount
  );
  const seller: any = useSelector((state: RootState) => state.seller.seller);
  const [page, setPage] = useState(1);
  const [orderSearchData, setOrderSearchData] = useState<any>();
  const [searchValue, setSearchValue] = useState<any>();
  let pageLimit = 10;
  const orderState = useSelector((state: RootState) => state.orderStatusUpdate);
  const [showStatusSnack, setShowStatusSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");

  useEffect(() => {
    if (seller) {
      setPage(1);
      dispatch(
        ordersStart({ sellerId: seller._id, itemsPerPage: pageLimit, page: 1 })
      );
    }
  }, [seller]);

  let pages: any = Math.ceil(orderCount / pageLimit);

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
    if (seller) {
      dispatch(
        ordersStart({
          sellerId: seller._id,
          itemsPerPage: pageLimit,
          page: value,
        })
      );
    }
  };

  const filteredData = (value: any) => {
    if (!value) {
      return;
    }
    const data = orders?.filter((item: any) => {
      return Object.values(item.Items[0].title)
        ?.join("")
        ?.toLowerCase()
        .includes(value?.toLowerCase());
    });
    return data;
  };

  const handleSearchValue = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (searchValue) {
      const data = filteredData(searchValue);
      setOrderSearchData(data);
    }
    if (!searchValue) {
      setOrderSearchData(null);
      return;
    }
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    if (!event.target.value) {
      setOrderSearchData(null);
      return;
    }
    if (event.target.value) {
      const data = filteredData(event.target.value);
      setOrderSearchData(data);
    }
  };

  const handleOrderStatusChange = (index: number, order:any, action:number) => {

    // Acknowledge the order
    if(action === StatusActions.AcknowledgedBySeller ||
       action === StatusActions.ReleasedForShipment || 
       action === StatusActions.Shipped ) {
      dispatch(
        orderStatusStart({
          id: order?.ID,
          sellerId: seller.sellerId,
          action: action
        })
      );
    } 
    // Cancel the order
    else if(action === StatusActions.Cancelled) {
      dispatch(
        orderStatusStart({
          id: order?.ID,
          sellerId: seller.sellerId,
          action: StatusActions.Cancelled,
          order: order
        })
      );
    }

  }

  useEffect(() => {
    if(orderState.error) {
      dispatch(
        ordersStart({ sellerId: seller._id, itemsPerPage: pageLimit, page: page })
      );
    }
    else if(orderState.success) {
      let newOrders:any[] = [];
      // Handle Cancelled Order
      if(orderState.action === StatusActions.Cancelled) {
        newOrders = orders.map((o:any, index:number) => {
          if(o.ID === orderState.orderId) {
            const updatedOrder = {...o};
            if(orderState.action === StatusActions.Cancelled) {
              updatedOrder.OrderStatus = 'Cancelled'
              setSnackMessage("Order Cancelled!")
              setShowStatusSnack(true);
            }
            return updatedOrder;
          }
          return o;
        });
      }
      // Update Order Status
      else {
        newOrders = orders.map((o:any, index:number) => {
          if(o.ID === orderState.orderId) {
            const updatedOrder = {...o};
            if(orderState.action === StatusActions.AcknowledgedBySeller) {
              updatedOrder.OrderStatus = 'AcknowledgedBySeller'
              setSnackMessage("Order Acknowledged!")
              setShowStatusSnack(true);
            }
            else if(orderState.action === StatusActions.ReleasedForShipment) {
              updatedOrder.OrderStatus = 'ReleasedForShipment'
              setSnackMessage("Order Released for Shippment!")
              setShowStatusSnack(true);
            }
            else if(orderState.action === StatusActions.Shipped) {
              updatedOrder.OrderStatus = 'Shipped'
              setSnackMessage("Order Shipped!")
              setShowStatusSnack(true);
            }
            return updatedOrder;
          }
          return o;
        });
      }
      dispatch(updateOrders({orders:newOrders}));

      // Set order status to initial state
      dispatch(resetState());
      
    }

  }, [orderState]);

  // Get value for a key from order json
  const getOrderValueForKey = (order: any, key: string) => {
    if (!order[key]) return "";
  
    let value = order[key];
  
    // Check if value is an array and has elements
    if (Array.isArray(value) && value.length > 0) {
      // Check if the first element is defined and not "None"
      if (value[0] !== undefined && value[0] !== "None") {
        return value[0]; // Return the first element as string
      }
    } else {
      // For single values, check if it's defined and not "None"
      if (value !== undefined && value !== "None") {
        return value; // Return the value as string
      }
    }
  };

  // Convert an order to sheet json
  const processSheetOrder = (order: any) => {
    let dataRows: any[] = [];

    // Iterate through each item in the order
    if (order.Items && order.Items.length > 0) {
      order.Items.forEach((item: any, index: number) => {
        let data: any = {};
        data['Order ID'] = getOrderValueForKey(order, 'ID');
        data['Order Status'] = getOrderValueForKey(order, 'OrderStatus');
        data['Payment Status'] = getOrderValueForKey(order, 'OrderPaymentStatus');
        data['Order Time'] = getOrderValueForKey(order, 'OrderDateUtc');
        data['Order Total Price'] = getOrderValueForKey(order, 'TotalPrice');
        data['Order Total Tax'] = getOrderValueForKey(order, 'TotalTaxPrice');
        data['Payment Type'] = getOrderValueForKey(order, 'PaymentType');
        data['Payment Cut'] = getOrderValueForKey(order, 'TotalPaysferAmount');
        data['Seller Amount'] = getOrderValueForKey(order, 'TotalSellerAmount');
  
        // Shipping Address
        if (order.address && order.address.length > 0) {
          const address = order.address[0];
          data['Shipping Address Name'] = `${address.FirstName} ${address.LastName}`;
          data['Shipping Address AddressLine1'] = address.AddressLine1;
          data['Shipping Address AddressLine2'] = address.AddressLine2 || "";
          data['Shipping Address City'] = address.City;
          data['Shipping Address PostalCode'] = address.PostalCode;
          data['Shipping Address State/Province'] = address.StateOrProvince;
          data['Shipping Address Country'] = address.Country;
          data['Shipping Address Phone Number'] = address.DaytimePhone;
          data['Shipping Address EmailAddress'] = address.EmailAddress;
        } else {
          // Provide defaults or empty strings if address is not available
          data['Shipping Address Name'] = "";
          data['Shipping Address AddressLine1'] = "";
          data['Shipping Address AddressLine2'] = "";
          data['Shipping Address City'] = "";
          data['Shipping Address PostalCode'] = "";
          data['Shipping Address State/Province'] = "";
          data['Shipping Address Country'] = "";
          data['Shipping Address Phone Number'] = "";
          data['Shipping Address EmailAddress'] = "";
        }
  
        // Item Details for the current item
        data['Item SKU'] = item.SellerSKU || "";
        data['Item Image'] = item.image || "";
        data['Item Title'] = item.title || "";
        data['Item Quantity'] = item.Quantity || "";
        data['Item Price'] = `$${parseFloat(item.UnitPrice).toFixed(2)}` || "";
        data['Item Total Price'] = `$${(item.UnitPrice * item.Quantity).toFixed(2)}` || "";
  
        // Push the data row into the dataRows array
        dataRows.push(data);
      });
    }
  
    return dataRows;
  };


  const onBeforeDownload = ():any[] => {
    let data:any[] = []

    if(orders && orders.length > 0) {
      orders.map((order: any, index: number) => {
        const orderRows:any[] = processSheetOrder(order);
        orderRows.forEach((orderRow) => data.push(orderRow));
      });
    }

    return data;
  }

  return (
    <>
      <section className="product-detils-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="gift-card-text">
              <div className="d-flex justify-content-between align-items-center">
                
                <p className="mb-0">My Orders</p>
                
                <div className="download-template-button">
                  <ExcelDownloaderTemplate
                    btnName={"Download Template"}
                    fileName={"products"}
                    onBeforeDownload={onBeforeDownload}
                  />
                </div>
              
              </div>

              </div>
              <div className="my-order-searchbar">
                <form onSubmit={handleSearchValue}>
                  <div className="form-group">
                    <input
                      onChange={handleSearch}
                      type="text"
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="Search your order here"
                    />
                  </div>
                </form>
                {orders && orders.length === 0 ? (
                  <p className="empty-cart">
                    <h2>No Orders Found</h2>
                  </p>
                ) : null}
                {orderSearchData && orderSearchData.length === 0 ? (
                  <p className="empty-cart">
                    <h2>No Orders Found</h2>
                  </p>
                ) : null}
              </div>
              <div className="my-orders-card-wrap">
                {orderSearchData
                  ? orderSearchData &&
                    orderSearchData.map((item: any, index: number) =>
                    <SingleOrderTemplate 
                      order={item}
                      orderIndex={index}
                      sellerId={seller.sellerId}
                      handleOrderStatusChange={handleOrderStatusChange}
                    />
                    )
                  : orders &&
                    orders.map((item: any, index: number) =>
                      <SingleOrderTemplate 
                        order={item}
                        orderIndex={index}
                        sellerId={seller.sellerId}
                        handleOrderStatusChange={handleOrderStatusChange}
                      />
                    )}
                <Pagination
                  count={pages}
                  page={page}
                  onChange={handleChangePage}
                />
              </div>
            </div>
          </div>
        </div>
        <Snackbar
          open={showStatusSnack}
          autoHideDuration={5000}
          onClose={() => setShowStatusSnack(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert
            onClose={() => setShowStatusSnack(false)}
            severity="success"
            variant="filled"
            sx={{ width: '100%' }}
          >
            {snackMessage}
          </Alert>
        </Snackbar>
      </section>
    </>
  );
};

export default OrderDetails;
