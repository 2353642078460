import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';

const OrderStatusStepper = ({activeStatus} : {activeStatus: number}) => {

    const steps = ["Pending", "Acknowledged", "ReleasedForShippment", "Shipped"];

    return (

        <>
            <Stepper activeStep={activeStatus}>
                {steps.map((label, index) => {
                const stepProps: { completed?: boolean } = {};
                const labelProps: {
                    optional?: React.ReactNode;
                } = {};
                return (
                    <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                );
                })}
            </Stepper>
        </>
    
    );
}

export default OrderStatusStepper;