import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import TemplateData from "utils/data/templateData/orders";
import { Button } from "@mui/material";

export const ExcelDownloaderTemplate = (props: any) => {
  const download = () => {
   
    var data = props.onBeforeDownload();
    let header = [...TemplateData.order];

    // Create a new workbook and a new worksheet
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet([header]);

    // Add data to worksheet, starting from the second row
    XLSX.utils.sheet_add_json(ws, data, { origin: 'A2', skipHeader: true });

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, 'orders');

    // Write the workbook to a binary array
    const excelBuffer = XLSX.write(wb, {
      bookType: 'xlsx',
      type: 'array',
      cellStyles: true,
    });
    const finalData = new Blob([excelBuffer], { type: "xlsx" });
    FileSaver.saveAs(finalData, "paysfer-orders.xlsx");
  };

  return (
    <>
      <Button
            size="small"
            style={{ backgroundColor: "rgb(48,79,49)" }}
            onClick={download}
            variant="contained"
            color="primary"
          >
        Download All Orders
      </Button>
    </>
  );
};
