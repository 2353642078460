import React from "react";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import "./styles.css";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import { returnFirstItemFromArray } from "../../utils";
import LocalShippingRoundedIcon from "@mui/icons-material/LocalShippingRounded";
import LocalPrintshopRoundedIcon from "@mui/icons-material/LocalPrintshopRounded";
import QrCodeScannerRoundedIcon from "@mui/icons-material/QrCodeScannerRounded";
import Link from "@material-ui/core/Link";
const SingleProductFilteredView = ({
  productDetails,
  continueWithThisItem = () => {},
  confirmView = false,
  fromMySales = false,
  fromMySoldSales = false,
  handleEdit = () => {},
}: any) => {
  if (!productDetails && !productDetails.title) {
    return null;
  }
  const {
    category: _category,
    title,
    description,
    brand: _brand,
    size: _size,
    color: _color,
    productgroupimageurl1,
    image,
    deliveryDetails,
    _id,
  } = productDetails;
  const { tracker_url, label_url, qr_code_url } = deliveryDetails || {};
  const category = returnFirstItemFromArray(_category);
  const brand = returnFirstItemFromArray(_brand);
  const size = returnFirstItemFromArray(_size);
  const color = returnFirstItemFromArray(_color);
  function handleEditModeClick() {
    handleEdit(productDetails);
  }
  return (
    <Grid
      container
      direction={"row"}
      key={title}
      className={!confirmView ? "singleProductWrapper" : ""}
      onClick={
        !confirmView ? () => continueWithThisItem(productDetails) : () => {}
      }
      alignItems={"center"}
      justifyContent={"flex-start"}
    >
      <Grid item className="imageContainerForSingleItemView">
        <img
          src={productgroupimageurl1 || image}
          className="productImageSet2"
          loading="lazy"
          alt="productImage"
        />
      </Grid>
      <Grid
        item
        xs={fromMySales ? 7 : 8}
        md={fromMySales ? 8 : 9}
        className="productImageDetails"
      >
        <Grid container direction={"row"}>
          <Grid item xs={12}>
            <Typography variant="h6" className="cardTitleForProducts">
              {title}
            </Typography>
            <div
              className="cardDescriptionForProducts"
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container alignItems={"center"} flexWrap={"wrap"}>
              {category ? (
                <Grid item>
                  <Chip label={`Category: ${category}`} className="theChip" />
                </Grid>
              ) : null}

              {brand ? (
                <Grid item>
                  <Chip label={`brand: ${brand}`} className="theChip" />
                </Grid>
              ) : null}
              {size ? (
                <Grid item>
                  <Chip label={`size: ${size}`} className="theChip" />
                </Grid>
              ) : null}
              {color ? (
                <Grid item>
                  <Chip label={`color: ${color}`} className="theChip" />
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {fromMySales ? (
        <Grid item xs={1} className="editOrViewContainer">
          <Grid container justifyContent={"center"} alignItems={"center"}>
            <Grid item>
              <EditIcon
                className="editButtonIcon"
                onClick={handleEditModeClick}
              />
            </Grid>
          </Grid>
        </Grid>
      ) : null}
      {fromMySoldSales ? (
        <Grid item xs={1} className="editOrViewContainer">
          <Grid
            container
            justifyContent={"center"}
            alignItems={"center"}
            spacing={1}
          >
            {label_url ? (
              <Grid item xs={12}>
                <Grid container spacing={1} direction={"row"}>
                  <Grid item>Label</Grid>
                  <Grid item>
                    <Link href={label_url} target="_blank">
                      <LocalPrintshopRoundedIcon />
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
            {tracker_url ? (
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item>Tracking</Grid>
                  <Grid item>
                    <Link href={tracker_url} target="_blank">
                      <LocalShippingRoundedIcon />
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            ) : null}

            {qr_code_url ? (
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item>QR Code</Grid>
                  <Grid item>
                    <Link href={qr_code_url} target="_blank">
                      <QrCodeScannerRoundedIcon />
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default SingleProductFilteredView;
