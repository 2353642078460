// reused functions

export function isTrue(value: any): boolean {
    return value === true || value === "true";
}

export function sleep(duration: number): Promise<void> {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, duration);
  });
}

export function returnFirstItemFromArray(item: any) {
  if (Array.isArray(item)) {
    return item[0] || "";
  } else {
    return item;
  }
}

export const getQueryParam = (paramName: string, searchString: string) => {
  if (!paramName || !searchString) {
    return "";
  } else {
    const searchParams = new URLSearchParams(searchString);
    return searchParams.get(paramName);
  }
};

export const checkIsValidArray = (data: any) => {
  return data && Array.isArray(data) && data.length > 0;
};

export const commonStringText = "#1 must be atleast #2 characters";
export const commonNumberText = "*Minimum #1 should be more than #2";

// reused constants

export const colorList: Array<{ color: String; code: String }> = [
  {
    color: "Red",
    code: "#ea2e2e",
  },
  {
    color: "Pink",
    code: "#fb1680",
  },
  {
    color: "Orange",
    code: "#fca628",
  },
  {
    color: "Yellow",
    code: "#ffee37",
  },
  {
    color: "Green",
    code: "#3c9c44",
  },
  {
    color: "Blue",
    code: "#137fc1",
  },
  {
    color: "Purple",
    code: "#7f0f81",
  },
  {
    color: "Gold",
    code: "#ffd72e",
  },
  {
    color: "Silver",
    code: "#e9ebec",
  },
  {
    color: "Black",
    code: "#000000",
  },
  {
    color: "Gray",
    code: "#929292",
  },
  {
    color: "White",
    code: "#ffffff",
  },
  {
    color: "Cream",
    code: "#e0e0ca",
  },
  {
    color: "Brown",
    code: "#663509",
  },
  {
    color: "Tan",
    code: "#d1b48e",
  },
];

export const sizeList: Array<String> = [
  "XS",
  "S",
  "M",
  "L",
  "XL",
  "XXL",
  "XXXL",
  "28",
  "30",
  "32",
  "34",
  "36",
  "38",
  "40",
  "42",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
];

export const titelize = (title: string) => {
  return title
    .replace(/[^a-zA-Z ]/g, "")
    .replace(/ /g, "_")
    .replace(/ *\([^)]*\) */g, "")
    .replace(/[()/\\]/g, ""); // removed unnecessary escape characters
};

export const openProductPage = (id:string, title:string, fromData:string = 'from-search'): void => {
  const formattedTitle = titelize(title);

  // Construct the URL with query parameters
  const url = new URL(`/product-page/${id}/${formattedTitle}`, "https://paysfer.com/");
  url.searchParams.set('src', fromData);

  // Open the new URL in a new tab
  window.open(url.toString(), '_blank');
}
  