import React from "react";
import "./styles.css";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "redux/store";
import { StepComponentsInterface } from "./ListAnItem";
import clsx from "clsx";
import SingleProductFilteredView from "./SingleProductFilteredView";
import {
  getAllProductsBasedOnFiltersStart,
  setUserSelectedProductToListAnItem,
  listAnItemWithProduct,
} from "redux/reducers/product";
// MUI
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import ArrowDropDownCircleRoundedIcon from "@material-ui/icons/ArrowDropDownCircleRounded";
import FilterListRoundedIcon from "@material-ui/icons/FilterListRounded";
import Paper from "@mui/material/Paper";
import Popover from "@mui/material/Popover";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import _Radio from "@mui/material/Radio";
import FormLabel from "@mui/material/FormLabel";
// import styled from "styled-components";
import ConfirmProduct from "./ConfirmProduct";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { Radio } from "@material-ui/core";

// const Radio = styled(_Radio)`
//   color: #304f31 !important;
//   padding: 3px !important;
// `;

const StartYourListing: React.FC<StepComponentsInterface> = ({
  handleNextStep,
}: StepComponentsInterface) => {
  // States and Selectors
  const dispatch = useDispatch();
  const history = useNavigate();
  const productsAreLoading = useSelector(
    (state: RootState) => state.product.isfilterProductsAreLoading
  );
  const filterProducts = useSelector(
    (state: RootState) => state.product.allProductsBasedOnFilter
  );
  const filterProductToListAnItem = useSelector(
    (state: RootState) => state.product.filteredProductToListAnItem
  );
  const [open, setOpen] = React.useState<boolean>(false);
  const [options, setOptions] = React.useState<Array<any>>([]);
  const [searchString, setSearchString] = React.useState("");
  const [popperDetails, setPopperDetails] = React.useState<any>({
    open,
    productDetails: {},
  });

  const loading = productsAreLoading;

  // handlers
  const handleOpen = () => {
    if (((searchString || "").trim() || "").length > 1) {
      setOpen(true);
    }
  };

  const handleWithoutFilter = () => {
    clearAllFiltered();
    history({
      // eslint-disable-next-line no-restricted-globals
      pathname: location.pathname,
      search: "",
    });
    handleNextStep();
  };

  const handleSearch = (newValue: any = "") => {
    if (
      ((newValue || "").trim() || "").length > 2 ||
      ((searchString || "").trim() || "").length > 2
    ) {
      const value = newValue || searchString;
      const filterProductDetails =
        filterProducts.find(
          ({ title, category }) => title === value || category === value
        ) || {};
        console.log('setUserSelectedProductToListAnItem',filterProductDetails)
      dispatch(
        setUserSelectedProductToListAnItem({
          filterProduct: filterProductDetails,
          filterProducts: filterProducts,
          userSearchString: value,
        })
      );
    } else {
      handleWithoutFilter();
    }
  };

  const handleInputChange = (event: any, newValue: any, reason: any) => {
    if (reason === "reset" && !productsAreLoading) {
      handleSearch(newValue);
      setSearchString(newValue);
    } else {
      setSearchString(newValue);
      if ((newValue.trim() || "").length > 2) {
        dispatch(
          getAllProductsBasedOnFiltersStart({
            headers: {
              page: 1,
              limit: 10,
              query: newValue,
            },
          })
        );
      } else if (((newValue || "").trim() || "").length <= 2) {
        setOptions([]);
        setOpen(false);
      }
    }
  };

  const customizeFilterData = React.useCallback((filterProducts: any) => {
    if (!Array.isArray(filterProducts) || filterProducts.length === 0) {
      return [];
    }
    const finalArray = filterProducts.map(
      ({ title, _id, productgroupimageurl1, category }) => {
        return { label: title, value: _id, productgroupimageurl1, category };
      }
    );

    return finalArray.sort((val) =>
      val.category?.toLowerCase() === searchString ? 0 : -1
    );
  }, []);

  const handlePopperDetails = ({ onChange, onClose }: any) => {
    if (onClose) {
      setPopperDetails({ ...onClose });
    } else if (onChange) {
      setPopperDetails((prevState: any) => ({
        ...prevState,
        productType: onChange.productType,
      }));
    }
  };

  function clearAllFiltered() {
    dispatch(setUserSelectedProductToListAnItem({}));
  }

  function continueWithThisItem(productDetails: any) {
    setPopperDetails({
      open: true,
      productDetails,
    });
  }

  function handleContinueToListing() {
    const { productDetails, productType } = popperDetails;
    dispatch(
      listAnItemWithProduct({
        ...productDetails,
        productType,
      })
    );
    
    history({
      // eslint-disable-next-line no-restricted-globals
      pathname: location.pathname,
      search: `productId=${productDetails._id}&productType=${productType}`,
    });
    clearAllFiltered();
    handleNextStep();
  }
  // UseEffects

  React.useEffect(() => {
    let active = true;

    if (loading) {
      return undefined;
    }

    (async () => {
      if (active && filterProducts) {
        setOptions(customizeFilterData(filterProducts));
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, filterProducts, customizeFilterData]);

  // JSX
  const noOptionText = (
    <Grid
      container
      direction={"row"}
      justifyContent={"flex-start"}
      alignItems={"center"}
      spacing={2}
      className="moreSpecificInfo"
    >
      <Grid item>
        <Typography>Run out of choices? No worries; Make your own.</Typography>
      </Grid>
      <Grid item>
        <button
          disabled={productsAreLoading}
          type="button"
          onClick={handleWithoutFilter}
          className="btn btn-primary header-search-btn searchIcon buttonStyles"
        >
          <i className="fas fa-arrow-right"></i>
        </button>
      </Grid>
    </Grid>
  );
  const renderOptions = (eachOptionData: any, option: any) => {
    return (
      <li {...eachOptionData}>
        <Grid
          container
          direction={"row"}
          justifyItems={"center"}
          alignItems={"center"}
        >
          <Grid item>
            <img
              src={option.productgroupimageurl1}
              className="optionImage"
              loading="lazy"
            />
          </Grid>
          <Grid item xs={9} md={10}>
            {option.label}
          </Grid>
        </Grid>
      </li>
    );
  };
  const paperComponent = (props: any) => (
    <Paper {...props}>
      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        className="moreSpecificInfo"
      >
        <Grid item xs={12}>
          <Typography variant="h6">Can you be more specific?</Typography>
          <Typography variant="subtitle2">
            Extra details will optimize your listing
          </Typography>
        </Grid>
      </Grid>
      {props.children}
      {options.length <= 0 && !loading ? noOptionText : null}
    </Paper>
  );
  const loadingText = (
    <Grid container justifyContent={"center"} alignItems={"center"}>
      <Grid item>
        <CircularProgress size={18} className="circularProgressElement" />
      </Grid>
    </Grid>
  );
  return (
    <React.Fragment>
      <Grid
        container
        direction={"row"}
        justifyContent={"center"}
        spacing={2}
        className="secondStepWrapper"
      >
        {filterProductToListAnItem?.userSearchString ? (
          <Grid item xs={12} md={11}>
            <Grid
              container
              direction={"row"}
              justifyContent={"center"}
              spacing={2}
            >
              <Grid item xs={12}>
                <Grid
                  container
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-around"}
                  spacing={2}
                >
                  <Grid item>
                    <ArrowDropDownCircleRoundedIcon
                      className="goBackToSearch"
                      onClick={clearAllFiltered}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <Grid
                      container
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Grid item>
                        <Typography variant="h6" className="foundMatchHeader">
                          Match for "
                          {filterProductToListAnItem.userSearchString}"
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <FilterListRoundedIcon className="filterIcon" />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={11} md={10}>
                <Grid
                  container
                  direction={"column"}
                  spacing={2}
                  className="relatedProductsWrapper"
                >
                  {filterProductToListAnItem.filterProduct?.title ? (
                    <React.Fragment>
                      <Grid item xs={12}>
                        <Typography variant="h6" className="foundMatchHeader">
                          Selected Product
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <SingleProductFilteredView
                          productDetails={
                            filterProductToListAnItem.filterProduct
                          }
                          continueWithThisItem={continueWithThisItem}
                        />
                      </Grid>
                    </React.Fragment>
                  ) : null}

                  <Grid item xs={12}>
                    <Typography variant="h6" className="foundMatchHeader">
                      Realted Product
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {Array.isArray(filterProductToListAnItem.filterProducts) &&
                    filterProductToListAnItem.filterProducts.length > 0
                      ? filterProductToListAnItem.filterProducts.map(
                          (productDetails: any) => {
                            return (
                              <SingleProductFilteredView
                                productDetails={productDetails}
                                continueWithThisItem={continueWithThisItem}
                              />
                            );
                          }
                        )
                      : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={11}>
            <Grid container direction={"column"} spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5" className="startyourlisting">
                  Start your listing
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <ClickAwayListener
                  onClickAway={() => {
                    setOpen(false);
                  }}
                >
                  <Autocomplete
                    freeSolo
                    fullWidth
                    clearOnBlur={false}
                    id="CategorySelection"
                    open={open}
                    loading={loading}
                    loadingText={loadingText}
                    options={options}
                    onOpen={handleOpen}
                    getOptionLabel={(option) => option.label}
                    onInputChange={handleInputChange}
                    renderOption={renderOptions}
                    PaperComponent={paperComponent}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label="Tell us which category you're selling"
                        classes={{
                          root: "autoCompleteRoot",
                        }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: productsAreLoading ? (
                            <CircularProgress
                              size={18}
                              className="circularProgressElement"
                            />
                          ) : (
                            <button
                              type="button"
                              onClick={handleSearch}
                              className={clsx(
                                "btn btn-primary header-search-btn searchIcon buttonStyles",
                                {
                                  disableButton:
                                    (searchString?.trim() || "").length <= 0,
                                }
                              )}
                            >
                              <i className="fas fa-arrow-right"></i>
                            </button>
                          ),
                        }}
                      />
                    )}
                  />
                </ClickAwayListener>
              </Grid>
            </Grid>
            <hr className="hrline" />
          </Grid>
        )}
        <Grid item xs={12} className="secondStepButtonContainer">
          <Grid container justifyContent={"center"} alignItems={"center"}>
            <Grid item>
              <Button
                variant="contained"
                className="buttonStyles listItemButtonWrapper"
                onClick={handleWithoutFilter}
                disabled={productsAreLoading || popperDetails.open}
              >
                Continue without Match
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Popover
        open={popperDetails.open}
        anchorEl={document.body}
        disableScrollLock={true}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        elevation={6}
      >
        <Grid
          container
          spacing={4}
          direction={"column"}
          className="container_popper"
        >
          <Grid item xs={12} sm={8} md={6}>
            <Grid
              container
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Grid item>
                <Typography variant="h6" className="confirmDetailsHeader">
                  Confirm Details
                </Typography>
              </Grid>
              <Grid item>
                <HighlightOffIcon
                  className="closeIcon"
                  onClick={() =>
                    setPopperDetails({ open: false, productDetails: {} })
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={8} md={6}>
            <SingleProductFilteredView
              productDetails={popperDetails.productDetails}
              confirmView
            />
          </Grid>
          <Grid item xs={12} sm={8} md={6}>
            <FormControl>
              <FormLabel id="radio-buttons-group-label">
                Select the condition of your item
              </FormLabel>
              <RadioGroup
                aria-labelledby="-radio-buttons-group-label"
                name="radio-buttons-group"
                onChange={(event) => {}}
              >
                <FormControlLabel value="New" control={<Radio />} label="New" />
                <FormControlLabel
                  value="Open Box"
                  control={<Radio />}
                  label="Open Box"
                />
                <FormControlLabel
                  value="Used"
                  control={<Radio />}
                  label="Used"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={8} md={6}>
            <Grid container justifyContent={"center"} alignItems={"center"}>
              <Grid item>
                <Button
                  variant="contained"
                  className="buttonStyles listItemButtonWrapper"
                  onClick={handleContinueToListing}
                  disabled={!popperDetails.productType}
                >
                  Continue to listing
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Popover>

      <ConfirmProduct
        popperDetails={popperDetails}
        handleContinueToListing={handleContinueToListing}
        handlePopperDetails={handlePopperDetails}
      />
    </React.Fragment>
  );
};

export default StartYourListing;
