import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import CircularProgress from "@mui/material/CircularProgress";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Button from "@mui/material/Button";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import Popover from "@mui/material/Popover";

import "./styles.css";
import { checkIsValidArray, getQueryParam, isTrue } from "../../utils";
import { StepComponentsInterface } from "./ListAnItem";
import ImageUploading, { ImageListType } from "react-images-uploading";
import PhotoBoothAdd from "./PhotoBoothAdd";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "redux/store";
import {
  createASingleProductStart,
  getProductFromSellLikeItem,
  updateSingleProductStart,
  deleteSingleProductStart,
} from "redux/reducers/product";
import {
  fetchCategoriesStartForSellLikeItem,
  fetchChildCategoryStartForSellLikeItem,
  categoryStateHandling,
} from "redux/reducers/categories";
import {
  returnFirstItemFromArray,
  commonStringText,
  commonNumberText,
} from "../../utils";
import CategoryItem from "./CategoryItem";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import FormHelperText from "@mui/material/FormHelperText";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";

interface Appstate {
  title: String;
  description: String;
  category: String;
  quantity: String;
  material: String;
  brand: String;
  size: String;
  color: String;
  originalPrice: String;
  saleprice: String;
  shipping: String;
  condition: String;
  shippingList: any;
  listingStatusList: string[];
  earningMade: String;
  productImages: any[];
  errorStates: {};
}

interface AppAction {
  type: String;
  value: any;
  categoryId?: any;
}

function sellPageReducer(state: Appstate, action: AppAction) {
  if (action.type === "title") {
    return {
      ...state,
      title: action.value,
    };
  }
  if (action.type === "description") {
    return {
      ...state,
      description: action.value,
    };
  }
  if (action.type === "category") {
    return {
      ...state,
      category: action.value,
      categoryId: action.categoryId,
    };
  }
  if (action.type === "quantity") {
    return {
      ...state,
      quantity: action.value,
    };
  }
  if (action.type === "material") {
    return {
      ...state,
      material: action.value,
    };
  }
  if (action.type === "brand") {
    return {
      ...state,
      brand: action.value,
    };
  }
  if (action.type === "size") {
    return {
      ...state,
      size: action.value,
    };
  }
  if (action.type === "width") {
    return {
      ...state,
      shippingwidth: action.value,
    };
  }
  if (action.type === "height") {
    return {
      ...state,
      shippingheight: action.value,
    };
  }
  if (action.type === "length") {
    return {
      ...state,
      shippinglength: action.value,
    };
  }
  if (action.type === "weight") {
    return {
      ...state,
      shippingweight: action.value,
    };
  }
  if (action.type === "color") {
    return {
      ...state,
      color: action.value,
    };
  }
  if (action.type === "originalPrice") {
    return {
      ...state,
      originalPrice: action.value,
    };
  }
  if (action.type === "saleprice") {
    return {
      ...state,
      saleprice: action.value,
    };
  }
  if (action.type === "shipping") {
    return {
      ...state,
      shipping: action.value,
    };
  }
  if (action.type === "fixedShippingPrice") {
    return {
      ...state,
      fixedShippingPrice: action.value,
    };
  }
  if (action.type === "setProductImages") {
    return {
      ...state,
      productImages: action.value,
    };
  }
  if (action.type === "earningMade") {
    return {
      ...state,
      earningMade: action.value,
    };
  }
  if (action.type === "condition") {
    return {
      ...state,
      condition: action.value,
    };
  }
  if (action.type === "UPDATE_ALL") {
    return {
      ...state,
      ...action.value,
    };
  }
  if (action.type === "shippingList") {
    return {
      ...state,
      ...action.value,
    };
  }
  if (action.type === "errorStates") {
    return {
      ...state,
      errorStates: action.value,
    };
  }
  return state;
}

function getInitialState(productDetails: any, editMode: Boolean) {
  if ((productDetails || {}).title) {
    const {
      category: _category,
      categroyId,
      title,
      description,
      brand: _brand,
      size: _size,
      color: _color,
      Quantity: _quantity,
      material: _material,
      productImages: _productImages,
      price: _price,
      saleprice: _saleprice,
      condition: _condition,
      productgroupimageurl1,
      productgroupimageurl2,
      productgroupimageurl3,
      productgroupimageurl4,
      productgroupimageurl5,
      productgroupimageurl6,
      productgroupimageurl7,
      shipping,
      shippingwidth: _shippingwidth,
      shippingheight: _shippingheight,
      shippinglength: _shippinglength,
      shippingweight: _shippingweight,
      _id,
      fixedShippingPrice,
    } = productDetails;
    let productImages = [];
    if (!editMode) {
      productImages = checkIsValidArray(_productImages)
        ? _productImages
        : [{ data_url: productgroupimageurl1 }];
    }
    const category = returnFirstItemFromArray(_category);
    const quantity = returnFirstItemFromArray(_quantity);
    const material = returnFirstItemFromArray(_material);
    const brand = returnFirstItemFromArray(_brand);
    const size = returnFirstItemFromArray(_size);
    const color = returnFirstItemFromArray(_color);
    const shippingwidth = returnFirstItemFromArray(_shippingwidth);
    const shippinglength = returnFirstItemFromArray(_shippinglength);
    const shippingheight = returnFirstItemFromArray(_shippingheight);
    const shippingweight = returnFirstItemFromArray(_shippingweight);
    const shippingList =
      shipping && shipping === "USPS"
        ? [
            "Free - Shipping paid by seller",
            "Fixed Shipping Price",
            "UPS",
            shipping,
          ]
        : ["Free - Shipping paid by seller", "Fixed Shipping Price", "UPS"];
    if (editMode) {
      const productGroupImageUrl = [
        productgroupimageurl2,
        productgroupimageurl3,
        productgroupimageurl4,
        productgroupimageurl5,
        productgroupimageurl6,
        productgroupimageurl7,
      ];
      if (productgroupimageurl1) {
        productImages.push({ data_url: productgroupimageurl1 });
      }
      productGroupImageUrl.forEach((eachImage) => {
        if (eachImage && returnFirstItemFromArray(eachImage)) {
          const imageItem = returnFirstItemFromArray(eachImage);
          productImages.push({ data_url: imageItem });
        }
      });
      let finalPrice = _saleprice;
      if (_condition === "Used" && _saleprice < 15) {
        finalPrice = _saleprice - 1.95;
      } else {
        finalPrice = _saleprice - (_saleprice / 100) * 8;
      }
      return {
        _id,
        title,
        description,
        category,
        categroyId,
        quantity,
        material,
        brand,
        color,
        size,
        originalPrice: _price,
        saleprice: _saleprice,
        condition: _condition,
        shipping: shipping || "",
        earningMade: "",
        shippingList,
        listingStatusList: ["New", "Open Box", "Used"],
        productImages,
        errorStates: {},
        shippingwidth,
        shippinglength,
        shippingheight,
        shippingweight,
        fixedShippingPrice,
      };
    } else {
      return {
        title,
        description,
        category,
        categroyId,
        quantity,
        material,
        brand,
        color,
        size,
        originalPrice: "",
        saleprice: "",
        shipping: "",
        condition: "",
        earningMade: "",
        shippingList,
        listingStatusList: ["New", "Open Box", "Used"],
        productImages,
        errorStates: {},
        shippingwidth,
        shippinglength,
        shippingheight,
        shippingweight,
        fixedShippingPrice,
      };
    }
  }
  return {
    title: "",
    description: "",
    category: "",
    categroyId: "",
    quantity: "",
    material: "",
    brand: "",
    size: "",
    color: "",
    originalPrice: "",
    saleprice: "",
    shipping: "",
    earningMade: "",
    condition: "",
    errorStates: {},
    productImages: [],
    listingStatusList: ["New", "Open Box", "Used"],
    shippingList: [
      "Free - Shipping paid by seller",
      "UPS",
      "Fixed Shipping Price",
    ],
    shippingwidth: "",
    shippinglength: "",
    shippingheight: "",
    shippingweight: "",
    fixedShippingPrice: "",
  };
}

const ListingForm: React.FC<StepComponentsInterface> = ({
  handleNextStep,
}: StepComponentsInterface) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const productId = getQueryParam("productId", location.search);
  const productType = getQueryParam("productType", location.search);
  const editMode = isTrue(getQueryParam("editMode", location.search));
  const productDetails = useSelector(
    (state: RootState) => state.product.listAnItemWithProduct
  );
  const sellLikeItemLoading = useSelector(
    (state: RootState) => state.categories.sellLikeItemLoading
  );
  const sellLikeItemCategoryList = useSelector(
    (state: RootState) => state.categories.sellLikeItemCategoryList
  );

  const productUnderCreation: any = useSelector(
    (state: RootState) => state.product.productUnderCreation
  );
  const dimensions = ["width", "height", "weight", "length"];
  const initialState = getInitialState(productDetails, editMode);
  const listAnItemText = editMode ? "Update Item" : "List An Item";
  const [reducerState, setReducerDispatcher] = React.useReducer<any>(
    sellPageReducer,
    initialState
  ) as [any, (value: AppAction) => void];
  const [categoryPopper, setCategoryPopper] = React.useState<any>({
    open: false,
    categoryDetails: [],
    categoryBreadcrumIds: [],
  });
  const [deleteProductDetailsPopper, setDeleteProductDetailsPopper] =
    React.useState(false);
  const handleDialogForDeleteProduct = () => {
    setDeleteProductDetailsPopper(false);
  };
  const isFixedPrice = reducerState.shipping === "Fixed Shipping Price";

  const handleProductImagesChange = (imageList: ImageListType) => {
    if (editMode) {
      setReducerDispatcher({
        type: "setProductImages",
        value: imageList,
      });
    } else {
      setReducerDispatcher({
        type: "setProductImages",
        value: imageList.filter(({ file }: any) => file),
      });
    }
  };

  const handleTextFieldChange = (event: any) => {
    setReducerDispatcher({
      type: event.target.name,
      value: event.target.value,
      categoryId: event.target.categoryId,
    });
  };
  const categoryListLength = (sellLikeItemCategoryList?.categories || [])
    .length;

  function deleteProduct() {
    const { _id } = reducerState;
    dispatch(
      deleteSingleProductStart({
        _id,
      })
    );
  }

  function listAnItem(e: any) {
    const {
      _id,
      title,
      description,
      category,
      categroyId,
      quantity: _quantity,
      size,
      originalPrice: _originalPrice,
      saleprice: _saleprice,
      shipping,
      condition,
      productImages,
      material,
      brand,
      color,
      shippingwidth: _shippingwidth,
      shippinglength: _shippinglength,
      shippingheight: _shippingheight,
      shippingweight: _shippingweight,
      fixedShippingPrice: _fixedShippingPrice,
    } = reducerState;
    const quantity = Number(_quantity);
    const originalPrice = Number(_originalPrice);
    const saleprice = Number(_saleprice);
    const shippingwidth = Number(_shippingwidth);
    const shippinglength = Number(_shippinglength);
    const shippingheight = Number(_shippingheight);
    const shippingweight = Number(_shippingweight);
    const fixedShippingPrice = Number(_fixedShippingPrice);
    let finalFixedPrice = _fixedShippingPrice;
    const categoryDetails = categoryPopper?.categoryBreadcrumIds;
    let parentCategoryIdList: any[] = [];
    let parentCategoryNameList: any[] = [];
    if (checkIsValidArray(categoryDetails)) {
      categoryDetails.forEach(({ categoryName, categoryId }: any) => {
        parentCategoryIdList.push(categoryId);
        parentCategoryNameList.push(categoryName);
      });
    }
    let errorList: any = {};
    if (!title || (title && title.trim().length < 12)) {
      errorList = {
        ...errorList,
        title: commonStringText.replace("#1", "*Title").replace("#2", "12"),
        scrollTillTop: true,
      };
    }
    if (!description || (description && description.trim().length < 12)) {
      errorList = {
        ...errorList,
        description: commonStringText
          .replace("#1", "*Description")
          .replace("#2", "12"),
        scrollTillTop: true,
      };
    }
    if (
      !checkIsValidArray(productImages) ||
      (checkIsValidArray(productImages) && productImages.length < 1)
    ) {
      errorList = {
        ...errorList,
        productImages: true,
        scrollTillTop: true,
      };
    }
    if (!category) {
      errorList = {
        ...errorList,
        category: true,
        scrollTillMid: true,
      };
    }
    if (!quantity || Number.isNaN(Number(quantity)) || quantity <= 0) {
      errorList = {
        ...errorList,
        quantity: commonNumberText.replace("#1", "quantity").replace("#2", "0"),
        scrollTillMid: true,
      };
    }

    if (!size || (Number(size) && size <= 0)) {
      errorList = {
        ...errorList,
        size: commonStringText.replace("#1", "*Size").replace("#2", "1"),
        scrollTillMid: true,
      };
    }
    if (
      !originalPrice ||
      Number.isNaN(Number(originalPrice)) ||
      originalPrice < 3
    ) {
      errorList = {
        ...errorList,
        originalPrice: commonNumberText
          .replace("#1", "original price")
          .replace("#2", "3"),
      };
    }
    if (!saleprice || Number.isNaN(Number(saleprice)) || saleprice < 3) {
      errorList = {
        ...errorList,
        saleprice: commonNumberText
          .replace("#1", "sale price")
          .replace("#2", "3"),
      };
    }
    if (!shipping) {
      errorList = {
        ...errorList,
        shipping: true,
      };
    }
    if (!condition && !productType) {
      errorList = {
        ...errorList,
        condition: true,
      };
    }
    if (
      !shippingwidth ||
      Number.isNaN(Number(shippingwidth)) ||
      shippingwidth <= 0
    ) {
      errorList = {
        ...errorList,
        width: commonNumberText.replace("#1", "width").replace("#2", "0"),
      };
    }
    if (
      !shippingheight ||
      Number.isNaN(Number(shippingheight)) ||
      shippingheight <= 0
    ) {
      errorList = {
        ...errorList,
        height: commonNumberText.replace("#1", "height").replace("#2", "0"),
      };
    }
    if (
      !shippinglength ||
      Number.isNaN(Number(shippinglength)) ||
      shippinglength <= 0
    ) {
      errorList = {
        ...errorList,
        length: commonNumberText.replace("#1", "length").replace("#2", "0"),
      };
    }
    if (
      !shippingweight ||
      Number.isNaN(Number(shippingweight)) ||
      shippingweight <= 0
    ) {
      errorList = {
        ...errorList,
        weight: commonNumberText.replace("#1", "weight").replace("#2", "0 lbs"),
      };
    }
    if (
      shipping === "Fixed Shipping Price" &&
      (!fixedShippingPrice || Number.isNaN(Number(fixedShippingPrice)))
    ) {
      errorList = {
        ...errorList,
        fixedShippingPrice: true,
      };
    }
    if (shipping !== "Fixed Shipping Price") {
      finalFixedPrice = "";
    }
    if (Object.entries(errorList).length > 0) {
      setReducerDispatcher({
        type: "errorStates",
        value: errorList,
      });
    } else {
      if (editMode) {
        dispatch(
          updateSingleProductStart({
            _id,
            title,
            description,
            category,
            categroyId,
            quantity,
            size,
            originalPrice,
            saleprice,
            shipping,
            productImages,
            material: material,
            brand,
            color,
            condition: condition || productType || "New",
            shippingweight: _shippingweight,
            shippingheight: _shippingheight,
            shippinglength: _shippinglength,
            shippingwidth: _shippingwidth,
            fixedShippingPrice: finalFixedPrice,
            parentCategoryIdList,
            parentCategoryNameList,
          })
        );
      } else {
        dispatch(
          createASingleProductStart({
            title,
            description,
            category,
            categroyId,
            quantity,
            size,
            originalPrice,
            saleprice,
            shipping,
            productImages,
            material,
            brand,
            color,
            condition: condition || productType,
            shippingweight: _shippingweight,
            shippingheight: _shippingheight,
            shippinglength: _shippinglength,
            shippingwidth: _shippingwidth,
            fixedShippingPrice: finalFixedPrice,
            parentCategoryIdList,
            parentCategoryNameList,
          })
        );
        setTimeout(()=>{
          navigate("/seller-products")
        },2000)
      }
    }

    if (errorList?.scrollTillTop) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else if (errorList.scrollTillMid) {
      window.scrollTo({ top: window.innerHeight, behavior: "smooth" });
    }
  }
  function handleCategoryClick() {
    if (!sellLikeItemCategoryList?.categories) {
      dispatch(fetchCategoriesStartForSellLikeItem({}));
    }
    if (sellLikeItemCategoryList?.categories) {
      setCategoryPopper((prev: any) => ({
        ...prev,
        open: true,
      }));
    }
  }

  function handleDigDown({ categoryName, categoryId }: any) {
    if (!categoryId || categoryId === "null") {
      setCategoryPopper((prev: any) => ({
        ...prev,
        open: false,
      }));
      handleTextFieldChange({
        target: {
          name: "category",
          value: categoryName,
          categoryId: categoryId,
        },
      });
    } else {
      dispatch(
        fetchChildCategoryStartForSellLikeItem({
          parentId: categoryId,
          categoryName,
          categoryId,
        })
      );
    }
  }

  function backToCateogry({ categoryId, index }: any) {
    const updateBreadCrums = categoryPopper.categoryBreadcrumIds.reduce(
      (
        acc: string,
        { categoryName: cName, categoryId: cId }: any,
        currIdx: number
      ) => {
        if (currIdx <= index) {
          return acc + "/" + cName + "#" + cId;
        }
        return acc;
      },
      ""
    );
    if (index === -1) {
      dispatch(
        categoryStateHandling({
          updateBreadCrums: " ",
          categoryId,
        })
      );
    } else {
      dispatch(
        categoryStateHandling({
          updateBreadCrums: updateBreadCrums,
          categoryId,
        })
      );
    }
  }

  useEffect(() => {
    if (productId && !productDetails?.title) {
      dispatch(
        getProductFromSellLikeItem({
          id: productId,
        })
      );
    }
    return () => {};
  }, [productId, productDetails]);

  useEffect(() => {
    const saleprice = Number(reducerState.saleprice);
    if (saleprice <= 0) {
      setReducerDispatcher({
        type: "earningMade",
        value: 0,
      });
    } else if (saleprice > 0) {
      let finalPrice = saleprice;
      if (productType === "Used" && saleprice < 15) {
        finalPrice = saleprice - 1.95;
      } else {
        finalPrice = saleprice - (saleprice / 100) * 8;
      }
      setReducerDispatcher({
        type: "earningMade",
        value: finalPrice.toFixed(2),
      });
    }

    return () => {};
  }, [productType, reducerState.saleprice]);

  useEffect(() => {
    setReducerDispatcher({
      type: "UPDATE_ALL",
      value: getInitialState(productDetails, editMode),
    });
  }, [productDetails]);
  useEffect(() => {
    if (
      sellLikeItemCategoryList?.categoryBreadCrum &&
      sellLikeItemCategoryList.currentCategoriesToDisplay
    ) {
      const combinedIdAndNameCategoryList =
        sellLikeItemCategoryList.categoryBreadCrum
          .split("/")
          .filter((item: string) => (item || "").trim());
      const categoryBreadcrumIds = combinedIdAndNameCategoryList.map(
        (eachCategoryItem: any) => {
          const [categoryName, categoryId] = eachCategoryItem
            .split("#")
            .filter((item: string) => item);
          return {
            categoryName,
            categoryId,
          };
        }
      );
      if (sellLikeItemCategoryList?.finalUserSelectedCategory?.useUps) {
        handleTextFieldChange({
          target: {
            name: "shippingList",
            value: {
              shipping: "UPS",
              shippingList: [
                "Free - Shipping paid by seller",
                "UPS",
                "Fixed Shipping Price",
              ],
            },
          },
        });
      } else {
        handleTextFieldChange({
          target: {
            name: "shippingList",
            value: {
              shipping: "USPS",
              shippingList: [
                "Free - Shipping paid by seller",
                "Fixed Shipping Price",
                "USPS",
                "UPS",
              ],
            },
          },
        });
      }
      if (sellLikeItemCategoryList?.finalUserSelectedCategory?.categoryId) {
        handleTextFieldChange({
          target: {
            name: "category",
            value:
              sellLikeItemCategoryList?.finalUserSelectedCategory?.categoryName,
            categoryId:
              sellLikeItemCategoryList?.finalUserSelectedCategory?.categoryId,
          },
        });
        setCategoryPopper((prev: any) => ({
          ...prev,
          categoryDetails: sellLikeItemCategoryList.currentCategoriesToDisplay,
          open: false,
          categoryBreadcrumIds,
        }));
      } else {
        setCategoryPopper((prev: any) => ({
          ...prev,
          categoryDetails: sellLikeItemCategoryList.currentCategoriesToDisplay,
          open: !sellLikeItemCategoryList.intialLoadFromUpdateProduct
            ? true
            : false,
          categoryBreadcrumIds,
        }));
      }
    } else {
      setCategoryPopper((prev: any) => ({
        ...prev,
        categoryDetails: sellLikeItemCategoryList?.categories,
        open: !sellLikeItemCategoryList.intialLoadFromUpdateProduct
          ? categoryListLength > 0
          : false,
      }));
    }

    return () => {};
  }, [categoryListLength, sellLikeItemLoading, sellLikeItemCategoryList]);

  return (
    <React.Fragment>
      <Grid
        container
        direction={"row"}
        spacing={2}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid item xs={11} md={11}>
          <Grid container spacing={2} alignItems={"center"}>
            <Grid item xs={12} md={6}>
              <Grid container direction={"column"} spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6" className="title">
                    Title
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Enter Title *"
                    required
                    name="title"
                    disabled={editMode}
                    onChange={handleTextFieldChange}
                    value={reducerState.title}
                    helperText={
                      reducerState.errorStates?.title
                        ? reducerState.errorStates?.title
                        : "Use the context that people would seek for if they were looking for your products."
                    }
                    error={!!reducerState.errorStates?.title}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container direction={"column"} spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6">Description</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    maxRows={2}
                    multiline
                    placeholder="Enter Desciption *"
                    required
                    disabled={editMode}
                    name="description"
                    onChange={handleTextFieldChange}
                    value={reducerState.description}
                    helperText={
                      reducerState.errorStates?.description
                        ? reducerState.errorStates?.description
                        : "Briefly describe your listing."
                    }
                    error={!!reducerState.errorStates?.description}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <hr className="hrline" />
        </Grid>
        <Grid item xs={11} md={11}>
          <Grid container direction={"column"} spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" className="title">
                Add a photo
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" className="descriptionForPhotos">
                Improve your buyer’s confidence by including as many as
                possible.
              </Typography>
              {reducerState.errorStates?.productImages ? (
                <FormHelperText error>
                  *Atleast a image should be required
                </FormHelperText>
              ) : null}
            </Grid>
            <Grid item xs={11}>
              <ImageUploading
                multiple
                value={reducerState.productImages}
                onChange={handleProductImagesChange}
                maxNumber={7}
                dataURLKey="data_url"
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageUpdate,
                  onImageRemoveAll,
                  onImageRemove,
                  dragProps,
                  errors,
                }) => {
                  const noImageAddedYet = imageList.length <= 0;
                  const imagesLengthMustLessThan7 = imageList.length < 7;
                  const imageViews = [
                    "Main Photo",
                    "Top",
                    "Bottom",
                    "Front",
                    "Back",
                    "Detail",
                    "Defect",
                  ];
                  return (
                    <Grid
                      container
                      spacing={2}
                      justifyContent={"center"}
                      alignItems={"center"}
                      className="imagePackageWrapper"
                    >
                      {!noImageAddedYet ? (
                        <Tooltip
                          title="Remove All Product Images"
                          arrow
                          classes={{
                            arrow: "toolTipArrow",
                            tooltip: "toolTip",
                          }}
                        >
                          <HighlightOffIcon
                            className="closeIcon removeAllImages"
                            onClick={onImageRemoveAll}
                          />
                        </Tooltip>
                      ) : null}

                      <Grid item xs={12} md={4}>
                        <Grid
                          container
                          direction={"row"}
                          justifyContent={"center"}
                        >
                          <Grid item xs={12}>
                            {noImageAddedYet ? (
                              <Grid
                                container
                                onClick={onImageUpload}
                                {...dragProps}
                                justifyContent={"center"}
                                alignItems={"center"}
                                spacing={2}
                                className="MainImageView"
                              >
                                <Grid item xs={12}>
                                  <Grid
                                    container
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                  >
                                    <AddPhotoAlternateOutlinedIcon />
                                  </Grid>
                                </Grid>
                                <Grid item>Drag and drop your photos</Grid>
                                <Grid item>
                                  <Button
                                    variant={"outlined"}
                                    className="buttonOutlinedStyles"
                                  >
                                    Add from computer
                                  </Button>
                                </Grid>
                              </Grid>
                            ) : (
                              <Grid
                                container
                                justifyContent={"center"}
                                alignItems={"center"}
                                direction={"column"}
                              >
                                <Grid item className="mainImageTitle">
                                  Main Image
                                </Grid>
                                <Grid item>
                                  <img
                                    src={imageList?.[0]?.data_url}
                                    alt=""
                                    className="mainImageRendered"
                                    loading="lazy"
                                  />
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                          {errors ? (
                            <Grid item>
                              {errors.maxNumber && (
                                <FormHelperText error={true}>
                                  *Number of selected images exceed maximum
                                  images 7.
                                </FormHelperText>
                              )}
                              {errors.acceptType && (
                                <FormHelperText error={true}>
                                  *Your selected file type is not allowed
                                </FormHelperText>
                              )}
                            </Grid>
                          ) : null}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={7}>
                        {noImageAddedYet ? (
                          <Grid
                            container
                            direction={"row"}
                            spacing={0}
                            className="secondaryImageContainer"
                          >
                            {imageViews.map((value, index) => {
                              return (
                                <Grid
                                  item
                                  xs={2}
                                  md={3}
                                  key={value + index}
                                  onClick={onImageUpload}
                                  className="SecondaryImageViewGrid"
                                  {...dragProps}
                                >
                                  {value}
                                </Grid>
                              );
                            })}
                          </Grid>
                        ) : (
                          <Grid container direction={"row"} spacing={2}>
                            {imageList.map((image, index) => {
                              return (
                                <PhotoBoothAdd
                                  image={image}
                                  index={index}
                                  onImageRemove={onImageRemove}
                                  onImageUpdate={onImageUpdate}
                                />
                              );
                            })}
                            {imagesLengthMustLessThan7 ? (
                              <Grid
                                item
                                onClick={onImageUpload}
                                className="SecondaryImageViewGrid AddPhotoView"
                                {...dragProps}
                              >
                                Add Photo
                              </Grid>
                            ) : null}
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  );
                }}
              </ImageUploading>
            </Grid>
          </Grid>
          <hr className="hrline" />
        </Grid>
        <Grid item xs={11} md={11}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Item Specific:</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container direction={"row"} spacing={2}>
                <Grid item xs={12} md={6} lg={6} xl={4}>
                  <Grid container spacing={1} direction="column">
                    <Grid item xs={12}>
                      <TextField
                        id="demo-simple-select-helper"
                        label="Category"
                        name="category"
                        value={reducerState.category}
                        onClick={handleCategoryClick}
                        placeholder="Select Your Category"
                        variant="outlined"
                        fullWidth
                        required
                        helperText={"Add a category to your product."}
                        error={!!reducerState.errorStates?.category}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={4}>
                  <Grid container spacing={1} direction="column">
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Quantity"
                        name="quantity"
                        placeholder="Enter the Quantity"
                        required
                        onChange={handleTextFieldChange}
                        value={reducerState.quantity}
                        helperText={
                          reducerState.errorStates?.quantity
                            ? reducerState.errorStates?.quantity
                            : "Add a Quantity to your product."
                        }
                        error={!!reducerState.errorStates?.quantity}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h6"></Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={4}>
                  <Grid container spacing={1} direction="column">
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Material"
                        name="material"
                        placeholder="Enter the material"
                        helperText="Describe the fabric or material of your product."
                        onChange={handleTextFieldChange}
                        value={reducerState.material}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={4}>
                  <Grid container spacing={1} direction="column">
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Brand"
                        name="brand"
                        placeholder="Enter the Brand / Designer"
                        helperText="Add Brand details about your product."
                        onChange={handleTextFieldChange}
                        value={reducerState.brand}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={4}>
                  <Grid container spacing={1} direction="column">
                    <Grid item xs={12}>
                      <TextField
                        id="demo-simple-select-helper"
                        label="Size"
                        name="size"
                        onChange={handleTextFieldChange}
                        placeholder="Select Price"
                        variant="outlined"
                        fullWidth
                        required
                        value={reducerState.size}
                        helperText={
                          reducerState.errorStates?.size
                            ? reducerState.errorStates?.size
                            : "Select size (if any)."
                        }
                        error={!!reducerState.errorStates?.size}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={4}>
                  <Grid container spacing={1} direction="column">
                    <Grid item xs={12}>
                      <TextField
                        id="demo-simple-select-helper"
                        label="Color"
                        name="color"
                        onChange={handleTextFieldChange}
                        placeholder="Select Color"
                        variant="outlined"
                        fullWidth
                        value={reducerState.color}
                        helperText={"Add color to your product."}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <hr className="hrline" />
        </Grid>
        <Grid item xs={11} md={11}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Dimensions:</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container direction={"row"} spacing={2}>
                {dimensions.map((dimension, index) => {
                  return (
                    <Grid item xs={12} md={3} lg={3} xl={3} key={dimension}>
                      <TextField
                        label={
                          dimension.charAt(0).toUpperCase() + dimension.slice(1)
                        }
                        name={dimension}
                        onChange={handleTextFieldChange}
                        placeholder={"Select " + dimension}
                        variant="outlined"
                        fullWidth
                        required
                        value={reducerState["shipping" + dimension]}
                        helperText={
                          reducerState.errorStates?.[dimension]
                            ? reducerState.errorStates?.[dimension]
                            : "Select " + dimension + " (if any)."
                        }
                        error={!!reducerState.errorStates[dimension]}
                        {...(dimension === "weight"
                          ? {
                              InputProps: {
                                endAdornment: "Oz",
                              },
                            }
                          : {
                              InputProps: {
                                endAdornment: "Inch",
                              },
                            })}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
          <hr className="hrline" />
        </Grid>
        <Grid item xs={11} md={11}>
          <Grid container direction={"column"} spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Pricing:</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container direction={"row"} spacing={2}>
                <Grid item xs={12} md={2}>
                  <FormControl
                    fullWidth
                    error={!!reducerState.errorStates?.condition}
                  >
                    <InputLabel id="*Product Condition">
                      *Product Condition
                    </InputLabel>
                    <Select
                      labelId="*Product Condition"
                      id="demo-simple-select-helper"
                      label="*Product Condition"
                      onChange={handleTextFieldChange}
                      placeholder="Product Condition"
                      variant="outlined"
                      fullWidth
                      name="condition"
                      value={reducerState.condition || productType}
                      error={!!reducerState.errorStates?.condition}
                    >
                      {reducerState.listingStatusList.map(
                        (value: any, index: React.Key) => {
                          return (
                            <MenuItem value={value} key={index}>
                              {value}
                            </MenuItem>
                          );
                        }
                      )}
                    </Select>
                    <FormHelperText>Specify Product Condition</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={5}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Original Price"
                    placeholder="Enter Original Price"
                    required
                    name="originalPrice"
                    onChange={handleTextFieldChange}
                    value={reducerState.originalPrice}
                    helperText={
                      reducerState.errorStates?.originalPrice
                        ? reducerState.errorStates?.originalPrice
                        : "Add price at which you want to sell your product"
                    }
                    error={!!reducerState.errorStates?.originalPrice}
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Sale Price"
                    placeholder="Enter Sale Price"
                    required
                    onChange={handleTextFieldChange}
                    name="saleprice"
                    value={reducerState.saleprice}
                    helperText={
                      reducerState.errorStates?.saleprice
                        ? reducerState.errorStates?.saleprice
                        : "Prices are in United States Dollars. No Need to mention curreny symbol"
                    }
                    error={!!reducerState.errorStates?.saleprice}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <hr className="hrline" />
        </Grid>
        <Grid item xs={11} md={11}>
          <Grid container direction={"column"} spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Shippping Options</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2">
                Shipping price will be displayed as chosen for potential buyers.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container direction={"row"} spacing={2}>
                <Grid item xs={12} md={isFixedPrice ? 4 : 6}>
                  <FormControl
                    fullWidth
                    error={!!reducerState.errorStates?.shipping}
                  >
                    <InputLabel id="*Select Shipping">
                      *Select Shipping
                    </InputLabel>
                    <Select
                      labelId="*Select Shipping"
                      id="demo-simple-select-helper"
                      label="*Select Shipping"
                      onChange={handleTextFieldChange}
                      placeholder="Select Shipping"
                      variant="outlined"
                      fullWidth
                      name="shipping"
                      value={reducerState.shipping}
                      error={!!reducerState.errorStates?.shipping}
                    >
                      {reducerState.shippingList.map(
                        (value: any, index: React.Key) => {
                          return (
                            <MenuItem value={value} key={index}>
                              {value}
                            </MenuItem>
                          );
                        }
                      )}
                    </Select>
                    <FormHelperText>
                      Provide how you want to ship the product
                    </FormHelperText>
                  </FormControl>
                </Grid>
                {isFixedPrice ? (
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Fixed Shipping Price"
                      placeholder="*Provide Fixed price"
                      required
                      helperText={
                        reducerState.errorStates?.fixedShippingPrice
                          ? reducerState.errorStates?.fixedShippingPrice
                          : "Fixed Shipping Price is required"
                      }
                      name="fixedShippingPrice"
                      value={reducerState.fixedShippingPrice}
                      onChange={handleTextFieldChange}
                    />
                  </Grid>
                ) : null}
                <Grid item xs={12} md={isFixedPrice ? 4 : 6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Earnings made by you"
                    required
                    helperText="Earnings made by you"
                    name="earningMade"
                    value={reducerState.earningMade}
                    disabled
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <hr className="hrline" />
        </Grid>
        <Grid item xs={11} md={11}>
          <Grid
            container
            justifyContent="center"
            alignItems={"center"}
            spacing={2}
          >
            <Grid item className="listItemButtonWrapper">
              <Button
                variant="contained"
                className="buttonStyles"
                onClick={listAnItem}
              >
                {productUnderCreation ? (
                  <Grid
                    container
                    justifyContent={"center"}
                    alignContent={"center"}
                  >
                    <Grid item>
                      <CircularProgress size={16} />
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle2">Listing...</Typography>
                    </Grid>
                  </Grid>
                ) : (
                  listAnItemText
                )}
              </Button>
            </Grid>
            {editMode ? (
              <Grid item className="listItemButtonWrapper">
                <Button
                  variant="contained"
                  className="deleteStyles"
                  onClick={() => {
                    setDeleteProductDetailsPopper(true);
                  }}
                >
                  <DeleteIcon />
                </Button>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
      <Popover
        open={categoryPopper.open}
        anchorEl={document.body}
        disableScrollLock={true}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        elevation={6}
      >
        <Grid
          container
          className="container_popper"
          spacing={4}
          direction={"column"}
        >
          <Grid item xs={12} sm={8} md={6}>
            <Grid
              container
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Grid item>
                <Typography variant="h6" className="confirmDetailsHeader">
                  Choose Your Category
                </Typography>
              </Grid>
              <Grid item>
                <HighlightOffIcon
                  className="closeIcon"
                  onClick={() =>
                    setCategoryPopper((prev: any) => ({ ...prev, open: false }))
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          {checkIsValidArray(categoryPopper?.categoryBreadcrumIds) ? (
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={11}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <Typography
                      variant="subtitle2"
                      className="getBackToCategory"
                      onClick={() =>
                        backToCateogry({ categoryId: null, index: -1 })
                      }
                    >
                      Home
                    </Typography>
                    {categoryPopper.categoryBreadcrumIds.map(
                      ({ categoryName, categoryId }: any, index: number) => {
                        if (
                          categoryPopper.categoryBreadcrumIds.length - 1 ===
                          index
                        ) {
                          return (
                            <Typography
                              variant="subtitle2"
                              className="currentCategoryLoc"
                            >
                              {categoryName}
                            </Typography>
                          );
                        } else {
                          return (
                            <Typography
                              variant="subtitle2"
                              className="getBackToCategory"
                              onClick={() => {
                                backToCateogry({ categoryId, index });
                              }}
                            >
                              {categoryName}
                            </Typography>
                          );
                        }
                      }
                    )}
                  </Breadcrumbs>
                </Grid>
              </Grid>
            </Grid>
          ) : null}

          <Grid item xs={12}>
            {sellLikeItemLoading ? (
              <CircularProgress size={20} className="circularProgressElement" />
            ) : (
              <Grid container>
                {checkIsValidArray(categoryPopper?.categoryDetails)
                  ? categoryPopper.categoryDetails.map(
                      (eachCategoryItem: any, index: number) => {
                        return (
                          <CategoryItem
                            categoryDetails={eachCategoryItem}
                            index={index}
                            handleDigDown={handleDigDown}
                            isFinal={
                              categoryPopper.categoryDetails.length - 1 ===
                              index
                            }
                          />
                        );
                      }
                    )
                  : null}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Popover>
      <Dialog
        onClick={handleDialogForDeleteProduct}
        open={deleteProductDetailsPopper}
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Typography>Do you want to delete the product?</Typography>
        </DialogTitle>

        <DialogActions>
          <Button onClick={handleDialogForDeleteProduct}>Cancel</Button>
          <Button
            autoFocus
            variant="contained"
            className="deleteStyles"
            onClick={deleteProduct}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default ListingForm;
