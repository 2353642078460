import { combineReducers } from '@reduxjs/toolkit';
import seller from './seller';
import onboarding from './bankOnboarding';
import categories from './categories';
import bulkUpload from './bulkUpload';
import sellerProducts from './sellerProducts';
import taxCodes from './taxCodes';
import orderDetails from './orderDetails';
import orderStatusUpdate from './orderStatusUpdate';
import updateProductQty from './updateProductQty';
import sessionHistory from './sessionHistory';
import productActions from "../reducers/product";

const rootReducer = combineReducers({
    seller: seller,
    onboarding: onboarding,
    categories: categories,
    bulkUpload: bulkUpload,
    sellerProducts: sellerProducts,
    taxCodes: taxCodes,
    orderDetails: orderDetails,
    product: productActions,
    orderStatusUpdate: orderStatusUpdate,
    updateProducts: updateProductQty,
    sessionHistory: sessionHistory
});

export default rootReducer;
