const data = {
    order: [
      "Order ID",
      "Order Status",
      "Payment Status",
      "Order Time",
      "Order Total Price",
      "Order Total Tax",
      "Payment Type",
      "Payment Cut",
      "Seller Amount",
      "Shipping Address Name",
      "Shipping Address AddressLine1",
      "Shipping Address AddressLine2",
      "Shipping Address City",
      "Shipping Address PostalCode",
      "Shipping Address State/Province",
      "Shipping Address Country",
      "Shipping Address Phone Number",
      "Shipping Address EmailAddress",
      "Item SKU",
      "Item Image",
      "Item Title",
      "Item Quantity",
      "Item Price",
      "Item Total Price"
    ]
  };
  
  export default data;
  