import "../../../styles/common.css";
import "./style.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openProductPage } from "utils";
import IconButton from '@material-ui/core/IconButton';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import {
  sellerProductsStart,
  sellerProductUpdateStart,
} from "redux/reducers/sellerProducts";
import { RootState } from "redux/store";
import { Box, Button, Grid, Pagination, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ExcelDownloaderTemplate } from "./excelDownloaderTemplate";

export const SellerProducts = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const rowRef:any = useRef(null);
  const [rows, setRows] = useState<any[]>([]);
  const [searchRows, setSearchRows] = useState<any[]>([]);
  const searchRef = useRef<HTMLInputElement | null>(null); 

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [uniqueKey, setUniqueKey] = useState<string>("");
  const [updatedQuantityValue, setUpdatedQuantityValue] = useState<number>();
  const [updatedPriceValue, setUpdatedPriceValue] = useState<number>();
  const [updatedSalePrice, setUpdatedSalePrice] = useState<number>();
  const [updatedDescription, setUpdatedDescription] = useState<string>("");
  const [position, setPosition] = useState<any>();

  const sellerProductsData = useSelector(
    (state: RootState) => state.sellerProducts.sellerProducts
  );
  const userState = useSelector((state: RootState) => state?.seller?.seller);
  useEffect(() => {
    dispatch(sellerProductsStart({ sellerId: userState?.sellerId }));
  }, [userState?.sellerId]);

  const focusRow = () => {
    if (rowRef.current) {
      rowRef.current.focus(); 
      window.scrollTo(0, position); 
    }
  };
  useEffect(() => {
    const rows: any[] = [];
    if (sellerProductsData && sellerProductsData.sellerProductsResponse) {
      sellerProductsData.sellerProductsResponse.forEach((item: any) => {
        rows.push(item);
      });
      setRows(rows);
      setSearchRows(rows);
    }
  }, [sellerProductsData]);

  // Get value for a key from product json
  const getValueForKey = (product:any, key:string) => {
    if (!product[key]) return "";
  
    let value = product[key];
  
    // Check if value is an array and has elements
    if (Array.isArray(value) && value.length > 0) {
      // Check if the first element is defined and not "None"
      if (value[0] !== undefined && value[0] !== "None") {
        return value[0]; // Return the first element as string
      }
    } else {
      // For single values, check if it's defined and not "None"
      if (value !== undefined && value !== "None") {
        return value; // Return the value as string
      }
    }
  
    return "";
  };

  // Convert a product to sheet json
  const processSheetProduct = (product:any) => {
    let data:any = {};
    data['Product Id'] = getValueForKey(product, 'parentSellerSKU');
    
    data['Category Name'] = getValueForKey(product, 'category');
    data['Tax Code'] = getValueForKey(product, 'taxCode');
    data['Parent Seller SKU'] = getValueForKey(product, 'parentSellerSKU');
    data['Seller SKU'] = getValueForKey(product, 'SellerSKU');
    data['Title'] = getValueForKey(product, 'title');
    data['Description'] = getValueForKey(product, 'description');
    data['GTIN'] = getValueForKey(product, 'gtin');
    data['MPIN'] = getValueForKey(product, 'mpn');
    data['Sale Price'] = getValueForKey(product, 'saleprice');
    data['Shipping Cost Standard'] = getValueForKey(product, 'shippingcoststandard');
    data['Shipping Cost Expedited'] = getValueForKey(product, 'shippingcostexpedited');
    data['Shipping Days'] = getValueForKey(product, 'shippingdays');
    data['Shipping Length'] = getValueForKey(product, 'shippinglength');
    data['Shipping Width'] = getValueForKey(product, 'shippingwidth');
    data['Shipping Height'] = getValueForKey(product, 'shippingheight');
    data['Shipping Weight'] = getValueForKey(product, 'shippingweight');
    data['Price'] = getValueForKey(product, 'price');
    data['Quantity'] = getValueForKey(product, 'Quantity');
    data['Listing Status'] = getValueForKey(product, 'ListingStatus');
    data['Product Group Image URL 1'] = getValueForKey(product, 'productgroupimageurl1');
    data['Product Group Image URL 2'] = getValueForKey(product, 'productgroupimageurl2');
    data['Product Group Image URL 3'] = getValueForKey(product, 'productgroupimageurl3');
    data['Product Group Image URL 4'] = getValueForKey(product, 'productgroupimageurl4');
    data['Product Group Image URL 5'] = getValueForKey(product, 'productgroupimageurl5');
    data['Product Group Image URL 6'] = getValueForKey(product, 'productgroupimageurl6');
    data['Product Group Image URL 7'] = getValueForKey(product, 'productgroupimageurl7');
    data['360 Image 1'] = getValueForKey(product, '360image1');
    data['360 Image 2'] = getValueForKey(product, '360image2');
    data['360 Image 3'] = getValueForKey(product, '360image3');
    data['360 Image 4'] = getValueForKey(product, '360image4');
    data['Video URL 1'] = getValueForKey(product, 'videourl1');
    data['Video URL 2'] = getValueForKey(product, 'videourl2');
    data['Video URL 3'] = getValueForKey(product, 'videourl3');
    data['Video URL 4'] = getValueForKey(product, 'videourl4');
    data['Brand'] = getValueForKey(product, 'brand');
    data['Country Of Origin'] = getValueForKey(product, 'countryoforigin');
    data['Condition'] = getValueForKey(product, 'condition');
    data['Color'] = getValueForKey(product, 'color');
    data['Material'] = getValueForKey(product, 'material');
    data['Gender'] = getValueForKey(product, 'gender');
    data['Age Group'] = getValueForKey(product, 'agegroup');
    data['Length'] = getValueForKey(product, 'length');
    data['Width'] = getValueForKey(product, 'width');
    data['Length Unit'] = getValueForKey(product, 'lengthUnit');
    data['Width Unit'] = getValueForKey(product, 'widthunit');

    return data;
  }

  // Build sheet product list from product list
  const onBeforeDownload = () => {
    let _data:any[] = [];
    
    if(rows && rows.length > 0) {
      rows.forEach((product:any) => {
        const sheetProduct = processSheetProduct(product);
        _data.push(sheetProduct);
      });
    }
    
    return _data;
  };


  const onSearch = (search: string) => {
    if(search == '') {
      setSearchRows(rows);
      return;
    }
    search = search.toLowerCase();
    const filtered = rows.filter((row) => {
      return (row.title && row.title.toLowerCase().includes(search)) ||
            (row.category && row.category.toLowerCase().includes(search)) ||
            (row.parentSellerSKU && row.parentSellerSKU.toLowerCase().includes(search));            
    });
    setSearchRows(filtered);
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent default behavior of form submission
      const value = searchRef.current?.value || ''; // Access input value using useRef
      if(rows && rows.length > 0 && value !== undefined) {
        onSearch(value);
      }
    }
  };

  return (
    <>
      <div style={{ display: "flex", alignItems:'center', alignContent:'center', justifyContent:'space-between' }}>
        <h2>Total Products: &nbsp;&nbsp;&nbsp;&nbsp;{rows.length}</h2>
        <input
          type="text"
          style={{
            marginLeft:'30px',
            width: '400px',
            padding: '12px',
            fontSize: '14px',
            border: '1px solid #ccc',
            borderRadius: '4px',
          }}
          placeholder="Search by Title / SKU / Category"
          ref={searchRef}
          onKeyDown={handleKeyDown}
        />
        <div style={{ marginLeft: "auto", display: "flex", gap: "10px"  }}>
          <ExcelDownloaderTemplate
            btnName={"Download Template"}
            fileName={"products"}
            onBeforeDownload={onBeforeDownload}
          />

          <Button
            size="small"
            style={{ backgroundColor: "rgb(48,79,49)" }}
            onClick={() => navigate("/single-product-upload")}
            variant="contained"
            color="primary"
          >
            Add Product
          </Button>
        </div>
      </div>
      <br />
      <div>
        {/* <input
          style={{width:"100%",height:"40px"}}
          
          placeholder={"Search by Product Title"}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
          defaultValue={searchValue}
        /> */}
      </div>

      <br />
      <TableContainer component={Paper} style={{ overflowX: 'auto', width: '100%' }}>
        <Table sx={{ tableLayout: 'fixed' }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{ width:'80px', position: 'sticky', left: 0, backgroundColor: 'white', zIndex: 1 }}>
                <h4>Serial No.</h4>
              </TableCell>
              <TableCell style={{  width:'100px', position: 'sticky', left: 80, backgroundColor: 'white', zIndex: 10 }}>
                <h4>Image</h4>
              </TableCell>
              <TableCell style={{ width: '150px' }}>
                <h4>Title</h4>
              </TableCell>
              <TableCell style={{ width: '300px' }}>
                <h4>Description</h4>
              </TableCell>
              <TableCell style={{ width: '150px' }}>
                <h4>Parent SKU</h4>
              </TableCell>
              <TableCell style={{ width: '100px' }}>
                <h4>Quantity</h4>
              </TableCell>
              <TableCell style={{ width: '100px' }}>
                <h4>Price</h4>
              </TableCell>
              <TableCell style={{ width: '100px' }}>
                <h4>Sale Price</h4>
              </TableCell>
              <TableCell style={{ width: '80px' }}>
                <h4>Action</h4>
              </TableCell>
              <TableCell style={{ width: '100px' }}>
                <h4>Category</h4>
              </TableCell>
              <TableCell style={{ width: '100px' }}>
                <h4>Live Link</h4>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {searchRows.map((row,index) => (
              <>
                <TableRow key={row.parentSellerSKU.toString()} ref = {rowRef}>
                <TableCell style={{ maxWidth: "80px", position: 'sticky', left: 0, backgroundColor: 'white', zIndex: 1 }}>
                  {index+1}
                </TableCell>
                <TableCell style={{ maxWidth: "100px", position: 'sticky', left: 80, backgroundColor: 'white', zIndex:1 }}>
                  <img src={row.productgroupimageurl1} alt={row.image} style={{ width: '50px', height: '50px', objectFit: 'cover' }} />
                </TableCell>
                <TableCell>
                  <div
                    style={{
                      display: '-webkit-box',
                      WebkitLineClamp: 4,
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxHeight: '6em', // Adjust the height based on the line height if needed
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    {row?.title}
                  </div>
                </TableCell>
                <TableCell >
                  {isEdit && row.parentSellerSKU == uniqueKey ? (
                    <textarea
                      rows={4}
                      cols={50}
                      onChange={(e: any) => {
                        setUpdatedDescription(e.target.value);
                      }}
                      defaultValue={row.description}
                      className="form-control"
                      id={row.parentSellerSKU}
                      placeholder="Enter Description"
                    />
                  ) : (
                    <div
                      style={{
                        display: '-webkit-box',
                        WebkitLineClamp: 4,
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxHeight: '6em', // Adjust the height based on the line height if needed
                        whiteSpace: 'pre-wrap'
                      }}
                    >
                      {row?.description}
                    </div>
                  )}
                </TableCell>
                <TableCell style={{ maxWidth: "200px" }}>
                  {row.parentSellerSKU}
                </TableCell>
                <TableCell>
                  {isEdit && row.parentSellerSKU == uniqueKey ? (
                    <input
                      onChange={(e: any) => {
                        setUpdatedQuantityValue(e.target.value);
                      }}
                      defaultValue={row?.Quantity[0]}
                      type="number"
                      className="form-control"
                      id={row.parentSellerSKU}
                      placeholder="Enter Quantity"
                    />
                  ) : (
                    row?.Quantity[0]
                  )}
                </TableCell>
                <TableCell>
                  {isEdit && row.parentSellerSKU == uniqueKey ? (
                    <input
                      onChange={(e: any) => {
                        setUpdatedPriceValue(e.target.value);
                      }}
                      defaultValue={row.price[0]}
                      type="number"
                      className="form-control"
                      id={row.parentSellerSKU}
                      placeholder="Enter Price"
                    />
                  ) : (
                    row.price[0]
                  )}
                </TableCell>
                <TableCell>
                  {isEdit && row.parentSellerSKU == uniqueKey ? (
                    <input
                      onChange={(e: any) => {
                        setUpdatedSalePrice(e.target.value);
                      }}
                      defaultValue={row?.saleprice[0]}
                      type="number"
                      className="form-control"
                      id={row.parentSellerSKU}
                      placeholder="Enter Sale Price"
                    />
                  ) : (
                    row?.saleprice[0]
                  )}
                </TableCell>
                <TableCell>
                  {
                    <Button
                      size="small"
                      style={{ backgroundColor: "rgb(48,79,49)" }}
                      disabled={isEdit && !(row.parentSellerSKU == uniqueKey)}
                      onClick={() => {
                        if (!isEdit) {
                          setIsEdit(true);
                          setUniqueKey(row.parentSellerSKU);
                          setUpdatedQuantityValue(row.Quantity[0]);
                          setUpdatedPriceValue(row.price[0]);
                          setUpdatedSalePrice(row.saleprice[0]);
                          setUpdatedDescription(row.description);
                          setPosition(window.scrollY); 
                        } else {
                          let rowData = {
                            ...row,
                            price: [Number(updatedPriceValue)],
                            saleprice: [Number(updatedSalePrice)],
                            Quantity: [Number(updatedQuantityValue)],
                            description: updatedDescription,
                          };

                          dispatch(sellerProductUpdateStart(rowData));

                          setIsEdit(false);
                          focusRow()
                        }
                      }}
                      variant="contained"
                      color="primary"
                    >
                      {!isEdit ? "Edit" : "Update"}
                    </Button>
                  }
                </TableCell>
                <TableCell>
                  {row.category}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  <a href="#" onClick={() => openProductPage(row._id, row.title)}>
                    Link
                  </a>
                </TableCell>
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
  component="div"
  count={100}
  page={page}
  onPageChange={handleChangePage}
  rowsPerPage={rowsPerPage}
  onRowsPerPageChange={handleChangeRowsPerPage}
/> */}
    </>
  );
};
