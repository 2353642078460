import logo from "assets/images/paysferLogoWhite.png";
import { useNavigate } from "react-router";

import "./style.css";
import "styles/common.css";
import "styles/responsive-common.css";

const Header: React.FC = () => {
  const navigate = useNavigate();

  return (
    <>
      <header>
        <div className="header-content-area">
          <div className="logo-wrap d-flex align-items-center">
            <a href="https://www.paysfer.com" className="">
              <img style={{margin:"20px"}} height="50px" src={logo} alt="Brand Logo" />
            </a>
            <b>
              <p style={{color:"#f5f5f5", fontSize:"28px"}}> Seller Center</p>
            </b>
          </div>
          <div className="header-search mr-3 d-none d-lg-block">
            <div>
              <div className="form-group search-provider"></div>
            </div>
          </div>
          <div className="heder-right">
            <ul>
              <li>
                <button
                  onClick={() =>
                    navigate("/seller/onboarding/application-start")
                  }
                  className="btn btn-primary"
                >
                  Request <br /> to Sell
                </button>
              </li>
              <li>
                <button
                  onClick={() => navigate("/seller/signin")}
                  className="btn btn-primary"
                >
                  Seller <br /> Sign-In
                </button>
              </li>
            </ul>
            <div className="header-address">
              <p>
                154 west 14 th st New York, NY 10011
                <br />
                <br />
               
                seller@paysfer.com
                
              </p>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
