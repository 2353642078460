import React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContainer from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

export interface StepComponentsInterface {
  handleNextStep: () => void;
}

const ListAnItem: React.FC<StepComponentsInterface> = ({
  handleNextStep,
}: StepComponentsInterface) => {
  return (
    <Grid container className="listAnItem">
      <Grid item xs={12}>
        <Grid container justifyContent={"center"}>
          <Grid item xs={12} md={6} lg={4}>
            <Card>
              <CardContainer>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item xs={12}>
                    <Typography variant="subtitle1">
                      Earn money by selling on Paysfer.
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Button
                      variant="contained"
                      className="buttonStyles"
                      onClick={handleNextStep}
                    >
                      List an item
                    </Button>
                  </Grid>
                </Grid>
              </CardContainer>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ListAnItem;
