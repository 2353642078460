import { createSlice } from '@reduxjs/toolkit';

interface State {
    isLoading: boolean
    error: boolean
    success:boolean
    orderId: number | undefined
    action: number | undefined
}

const initialState: State = {
    isLoading: true,
    error: false,
    success:false,
    orderId: undefined,
    action: undefined,
};

export const orderStatusSlice = createSlice({
    name: 'orderDetails',
    initialState,
    reducers: {
        resetState: (state: State) => ({
            ...initialState
        }),
        orderStatusStart: (state: State, action) => ({
            ...initialState,
            isLoading: true,
            success:false,
            orderId: undefined,
            action: undefined
        }),
        orderStatusSuccess: (state: State, action) => ({
            ...state,
            isLoading: false,
            error: false,
            success:true,
            orderId: action.payload.response.orderId,
            action: action.payload.response.action
        }),
        orderStatusFailure: (state: State) => ({
            ...state,
            error: true,
            isLoading: false,
            success:false,
            orderId: undefined,
            action: undefined
        })
    }
});

export const {
    orderStatusFailure,
    orderStatusSuccess,
    resetState,
    orderStatusStart
} = orderStatusSlice.actions;
export default orderStatusSlice.reducer;
