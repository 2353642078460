import { call, put, takeLatest } from "redux-saga/effects";
import {
    sellerProductsStart,
    sellerProductsSuccess,
    sellerProductsFailure,
    sellerProductUpdateStart,
    sellerProductUpdateSuccess,
    sellerProductUpdateFailure    
} from 'redux/reducers/sellerProducts';
import { getprouducts, updateprouducts } from "redux/services/sellerProducts";

function* sellerProductsSagaWatcher() {
  yield takeLatest(
    [sellerProductsStart.type, sellerProductUpdateStart.type],
    sellerProductsWorker,
  );
}

function* sellerProductsWorker(action: any): any {
  try {
    switch (action.type) {
      case sellerProductsStart.type: {
        const response: any = yield call(getprouducts, action.payload);
        yield put(sellerProductsSuccess({sellerProductsResponse : response.ResponseBody}));
      }
        break;
        case sellerProductUpdateStart.type: {
          const response: any = yield call(updateprouducts, action.payload);
          console.log('resss',response);
          yield put(sellerProductUpdateSuccess({sellerProductsResponse : response.ResponseBody}));
        }
          break;
        

      default:
        break;
    }
  } catch (err: any) {
    console.error(`Error occuring while calling an action ${action.type}`, err);

    if (action.type === sellerProductsStart.type) {
      console.error(err?.response?.data?.msg);
      yield put(sellerProductsFailure());
    }
  }
}

export default sellerProductsSagaWatcher;
