import { Accordion, 
    AccordionDetails, 
    AccordionSummary,
    Box,
    Typography,
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    Paper
} from "@mui/material";
import { 
    ExpandMore,
} from "@mui/icons-material";
import { useEffect, useState } from "react";
import { getSessionsStart } from "redux/reducers/sessionHistory";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import Loader from "components/common/Loader";

type SessionProduct = {
    title:string,
    parentSellerSKU: string,
    errorMessgae:string,
}

type Session = {
    sessionId: string,
    date:string,
    Products: SessionProduct[]
}

const Session = ( { sessionData } : {sessionData: Session}) => {

    return (
        <Accordion style={{marginBottom:'20px'}}>
            
            <Box sx={{ display: "flex" }}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel2a-header"
                    sx={{ flexGrow: 1 }}
                >
                    <div style={{display:"flex", flexDirection:"column"}}>
                        <Typography variant="h6" style={{fontSize:"14px"}}>
                            {sessionData.date}   ({sessionData.Products.length} Products)
                            </Typography>
                        <Typography style={{fontSize:"11px"}}>
                            Session ID: {sessionData.sessionId}
                        </Typography>
                    </div>
                </AccordionSummary>
            </Box>

            <AccordionDetails>

                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                        <TableRow>
                            <TableCell>Sr. No.</TableCell>
                            <TableCell>Title</TableCell>
                            <TableCell>SKU</TableCell>
                            <TableCell style={{ width: '50%' }}>Reason for Rejection</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {sessionData.Products.map((product, index) => (
                            <TableRow key={index}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{product.title}</TableCell>
                                <TableCell>{product.parentSellerSKU}</TableCell>
                                <TableCell>
                                    <Typography noWrap={false} variant="body2" color="textSecondary" component="div">
                                    {product.errorMessgae}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                
            </AccordionDetails>
      </Accordion>
    ); 
}

const SessionList = ({sessionList}:{sessionList:Session[]}) => {

    return (
        <>
            {sessionList.map((session:Session) => {
                return (
                    <Session sessionData={session}/>
                );})
            }
        </>
    );
}

const SessionHistory = () => {

    const dispatch = useDispatch();
    const seller = useSelector(
        (state: RootState) => state.seller.seller
    );  
    const sessionHistoryData = useSelector(
        (state: RootState) => state.sessionHistory.sessionHistoryResponse
    );
    const isLoading = useSelector(
        (state: RootState) => state.sessionHistory.isLoading
    );

    const [sessionList, setSessionList] = useState<Session[]>([]);

    useEffect(() => {
        dispatch(
            getSessionsStart({
              sellerId:seller.sellerId
            })
        );
    },[]);

    const formatReadableDate = (dateString:string) => {
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const options: Intl.DateTimeFormatOptions  = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZoneName: 'short',
            timeZone: userTimeZone? userTimeZone :"America/New_York"
        };
          
        const formattedDate = new Date(dateString).toLocaleString('en-US', options);
        return formattedDate;
    }

    // Build session list
    useEffect(() => {
        if(sessionHistoryData) {
            // Build session list 
            let list: Session[] = [];
            for(const sessionId in sessionHistoryData) {
                // If session has failed products
                if(sessionHistoryData[sessionId].length> 0){
                    
                    // Get products
                    let products: SessionProduct[] = sessionHistoryData[sessionId].map((obj:any) => {
                        const p: SessionProduct = {
                            title: obj.productDetails.title,
                            parentSellerSKU: obj.productDetails.parentSellerSKU,
                            errorMessgae: obj.productDetails.errorMsg
                        };
                        return p;
                    });
                    // Create session object
                    let data: Session = {
                        sessionId: sessionId, 
                        date: formatReadableDate(sessionHistoryData[sessionId][0].updatedAt),
                        Products: products
                    }
                    list.push(data);
                } 
            }
            // Set session history data in the sate
            setSessionList(list);
        }
    },[sessionHistoryData]);


    const SessionHistoryContent = () => {
        if(sessionList && sessionList.length > 0) {
            return (
                <SessionList sessionList={sessionList}/>
            );
        }
        else {
            return (
                <Paper elevation={1} style={{ textAlign: 'center', padding: '20px', borderRadius: '10px', marginTop: '50px' }}>
                    <Typography variant="h6" color="textSecondary">
                        No Session History Available!
                    </Typography>
                </Paper>
            );
        }
    }

    
    return (
        <div style={{marginTop:'10px'}}>
            {isLoading? 
                <Loader/>
                :
                SessionHistoryContent()
            }
        </div>
    );
}

export default SessionHistory;