import { axiosInstanceMain as axios } from "api/axiosController";
import { getAccessToken } from "utils/auth";

export const getCategoryData = async () => {
  let uri = "/categories";
  try {
    const res = await axios.get(uri);
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const getTopCategories = async () => {
  let uri = "/categories/top";
  try {
    const res = await axios.get(uri);
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const getChildCategories = async (data: any) => {
  let uri = "/categories/childCategories";
  try {
    const res = await axios.post(
      uri,
      { parentId: data?.parentId },
      {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }
    );
    return res.data;
  } catch (e) {
    throw e;
  }
};
