import axiosInstance, { axiosInstanceAdmin } from "api/axiosController";
import { getUser, getAccessToken } from "utils/auth";

export const getorders = async (data:any) => {
  let uri = `/seller-orders-details`;
  
  try {
    const res = await axiosInstance.post(uri,data);
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const orderAcknowledged = async (data:any) => {
  let uri = `/orders/${data.id}/acknowledged`;

  try {
    const res = await axiosInstanceAdmin.post(uri,data);
    res.data.orderId = data.id;
    res.data.action = data.action;
    return res.data;
  } catch (e) {
    throw e;
  }
};   

export const orderCancel = async (data:any) => {
  let uri = `/orders/${data.id}/cancel`;
  try {

    let items:any[] = [];
    data.order.Items.map((o:any) => {
      items.push({
        "ID":o.ID,
        "SellerSku": o.parentSellerSKU,
        "Quantity": o.Quantity,
        "Reason": "Marketplace_CancelledBySeller"
      });
    });

    const cancelOrder = {
      "OrderID":data.id,
      "Items":items
    }

    const res = await axiosInstanceAdmin.post(uri, cancelOrder);
    res.data.orderId = data.id;
    res.data.action = data.action;
    return res.data;
  } catch (e) {
    throw e;
  }
};   

export const releaseForShipment = async (data:any) => {
  let uri = `/orders/${data.id}/releaseforshipment`;

  try {
    const res = await axiosInstanceAdmin.post(uri,data);
    res.data.orderId = data.id;
    res.data.action = data.action;
    return res.data;
  } catch (e) {
    throw e;
  }
};   

export const shipOrder = async (data:any) => {
  let uri = `/orders/${data.id}/ship`;

  try {
    const res = await axiosInstanceAdmin.post(uri,data);
    res.data.orderId = data.id;
    res.data.action = data.action;
    return res.data;
  } catch (e) {
    throw e;
  }
};   