import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface State {
    isLoading: boolean;
    error: boolean;
    sessionHistoryResponse: any;
    sessionHistoryStatus: string;
}

const initialState: State = {
    isLoading: false,
    error: false,
    sessionHistoryResponse: {},
    sessionHistoryStatus: ""
};
  
export const sessionHistorySlice = createSlice({
    name:'sessionHistory',
    initialState,
    reducers:{
        getSessionsStart: (state: State, action) => ({
            ...initialState,
            isLoading: true
        }),
        getSessionsSuccess: (state: State, action: PayloadAction<{ sessionHistoryResponse: any; sessionHistoryStatus: string }>) => ({
            ...state,
            isLoading: false,
            sessionHistoryResponse: action.payload.sessionHistoryResponse,
            sessionHistoryStatus: action.payload.sessionHistoryStatus
        }),
        getSesssionFailure: (state: State) => ({
            ...state,
            error: true,
            isLoading: false
        })
    }
});

export const {
    getSessionsStart,
    getSessionsSuccess,
    getSesssionFailure
} = sessionHistorySlice.actions;

export default sessionHistorySlice.reducer;