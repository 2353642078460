import React from "react";
import Popover from "@mui/material/Popover";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SingleProductFilteredView from "./SingleProductFilteredView";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import _Radio from "@mui/material/Radio";
import FormLabel from "@mui/material/FormLabel";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
// import styled from "styled-components";
// const Radio = styled(_Radio)`
//   color: #304f31 !important;
//   padding: 3px !important;
// `;

const ConfirmProduct = ({
  popperDetails = { open: false },
  handlePopperDetails = () => {},
  handleContinueToListing = () => {},
}: any) => {
  return (
    <Popover
      open={popperDetails.open}
      anchorEl={document.body}
      disableScrollLock={true}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      elevation={6}
    >
      <Grid
        container
        spacing={4}
        direction={"column"}
        className="container_popper"
      >
        <Grid item xs={12} sm={8} md={6}>
          <Grid
            container
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Grid item>
              <Typography variant="h6" className="confirmDetailsHeader">
                Confirm Details
              </Typography>
            </Grid>
            <Grid item>
              <HighlightOffIcon
                className="closeIcon"
                onClick={() =>
                  handlePopperDetails({
                    onClose: {
                      open: false,
                      productDetails: {},
                    },
                  })
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={8} md={6}>
          <SingleProductFilteredView
            productDetails={popperDetails.productDetails}
            confirmView
          />
        </Grid>
        <Grid item xs={12} sm={8} md={6}>
          <FormControl>
            <FormLabel id="radio-buttons-group-label">
              Select the condition of your item
            </FormLabel>
            <RadioGroup
              aria-labelledby="-radio-buttons-group-label"
              name="radio-buttons-group"
              onChange={(event) => {
                handlePopperDetails({
                  onChange: {
                    productType: event.target.value,
                  },
                });
              }}
            >
              <FormControlLabel value="New" control={<Radio />} label="New" />
              <FormControlLabel
                value="Open Box"
                control={<Radio />}
                label="Open Box"
              />
              <FormControlLabel value="Used" control={<Radio />} label="Used" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={8} md={6}>
          <Grid container justifyContent={"center"} alignItems={"center"}>
            <Grid item>
              <Button
                variant="contained"
                className="buttonStyles listItemButtonWrapper"
                onClick={handleContinueToListing}
                disabled={!popperDetails.productType}
              >
                Continue to listing
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Popover>
  );
};

export default ConfirmProduct;
