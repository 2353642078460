import React from "react";
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import ArrowDropDownCircleRoundedIcon from "@material-ui/icons/ArrowDropDownCircleRounded";
import ListAnItem from "./ListAnItem";
import StartYourListing from "./StartYourListing";
import ListingForm from "./ListingForm";
import "./styles.css";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";

const steps = ["List An Item", "Choose A Category", "Finish With Listing"];

const SingleProductUpload: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  const goBackToPreviousRoute = () => {
    // history.goBack();
  };
  const handleTopStep = (value: number) => {
    setActiveStep(value);
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  React.useEffect(() => {
    if (location.search) {
      setActiveStep(2);
    }
  }, [location]);
  return (
    <Grid container justifyContent={"center"} alignItems={"center"} spacing={2}>
      <Grid item xs={12} md={activeStep === 0 ? 12 : 9} className="sellItem">
        <Grid
          container
          direction={"row"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          {activeStep === 0 && (
            <Grid
              item
              xs={1}
              className="goBackContainer"
              onClick={goBackToPreviousRoute}
            >
              <ArrowDropDownCircleRoundedIcon className="goBackToSearch goBackToPreviousIcon" />
              <Typography variant="subtitle2" className="goBackToPreviousRoute">
                back
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} sm={10} md={activeStep === 0 ? 9 : 12}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                return (
                  <Step
                    key={label + index}
                    onClick={() => {
                      if (index < 2) handleTopStep(index);
                    }}
                    classes={{
                      root: "stepRoot",
                    }}
                  >
                    <StepLabel
                      classes={{
                        iconContainer: "iconContainer",
                      }}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Grid>
          <Grid
            item
            xs={false}
            sm={false}
            sx={{
              display: activeStep === 0 ? "" : "none",
            }}
            md={activeStep === 0 ? 1 : false}
          ></Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={9}>
        {activeStep === 0 ? <ListAnItem handleNextStep={handleNext} /> : null}

        {activeStep === 1 ? (
          <StartYourListing handleNextStep={handleNext} />
        ) : null}

        {activeStep === 2 ? <ListingForm handleNextStep={handleNext} /> : null}
      </Grid>
    </Grid>
  );
};

export default SingleProductUpload;
