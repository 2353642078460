import React from "react";
import Grid from "@mui/material/Grid";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

const PhotoBoothAdd = ({ image, index, onImageRemove, onImageUpdate }: any) => {
  const [isHovered, setIsHovered] = React.useState(false);
  return (
    <Grid
      item
      key={"dataURL" + index}
      className="SecondaryImageView"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img
        className="SecondaryImageViewForImage"
        src={image["data_url"]}
        alt=""
        loading="lazy"
      />
      {isHovered && (
        <React.Fragment>
          <CancelOutlinedIcon className="removeImage" onClick={onImageRemove} />
          <div className="updateImage" onClick={onImageUpdate}>
            Update Image
          </div>
        </React.Fragment>
      )}
    </Grid>
  );
};

export default PhotoBoothAdd;
