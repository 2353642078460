import { put, call, takeLatest } from "redux-saga/effects";
import { 
    getSessionsStart,
    getSessionsSuccess,
    getSesssionFailure 
} from "redux/reducers/sessionHistory";
import { getSessionsHistoryAPI } from "redux/services/ sessionHistory";

function* sessionHistoryWorker(action: any): any  {
    try {

        switch(action.type) {

            case getSessionsStart.type:
                const {sellerId} = action.payload;
                const response: any = yield call(getSessionsHistoryAPI, sellerId);
                if(response) {
                    yield put(getSessionsSuccess({sessionHistoryResponse:response.ResponseBody, sessionHistoryStatus: response.Status as string}));
                }
                break;
            default: 
            break;
        }
        
    } catch (error: any) {
        console.error(`Error occuring while calling an action ${action.type}`, error);

        if (action.type === getSessionsStart.type) {
        console.error(error?.response?.data?.msg);
            yield put(getSesssionFailure());
        }
    }
}

function* sessionHistorySagaWatcher() {
    yield takeLatest(
        [
            getSessionsStart.type,
            getSesssionFailure.type,
            getSessionsSuccess.type
        ],
        sessionHistoryWorker
    );
}

export default sessionHistorySagaWatcher;